.page-block-content {

    .content-block {

        &.zoia-page-hero-block {

            --heading-color: var(--sf-color-white);
            
            --zero-hero-height: 5rem;
            --tabp-hero-height: 20rem;
            --tabl-hero-height: var(--tabp-hero-height);
            --desk-hero-height: var(--tabl-hero-height);
            --hero-height: var(--zero-hero-height);

            --zero-hero-text-width: 100%;
            --tabp-hero-text-width: 20rem;
            --tabl-hero-text-width: var(--tabp-hero-text-width);
            --desk-hero-text-width: var(--tabl-hero-text-width);
            --hero-text-width: var(--zero-hero-text-width);
            
            position: relative;
            overflow: hidden;
            
            margin-bottom: calc(var(--bottom-margin) * -2);
            
            .dimming-overlay {
                
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;

                background: transparent;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.35) 25%, transparent 50%);

                @include sf-media($from: tabp) {

                    left: 0;
                    top: 0;
                    bottom: 0;
                    width: 50vw;

                    background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 30%, transparent 100%);
                }
            }
            
            .image-corner-overlay {

                position: absolute;
                overflow: hidden;
                right: 0;
                bottom: 0;
                transform: translateX(40%);

                & > img {
                    width: auto;
                    min-height: 18rem;
                    max-height: 18rem;
                    max-width: max-content;
                }
                
                @include sf-media($from: tabp) {

                    transform: none;

                    & > img {
                        min-height: 30rem;
                        max-height: 30rem;
                    }
                }
            }
            
            .text-content {

                padding-bottom: 2rem;
                
                @include sf-media($from: tabp) {

                    --hero-height: var(--tabp-hero-height);
                    --hero-text-width: var(--tabp-hero-text-width);

                    padding-bottom: 4rem;
                }

                @include sf-media($from: tabl) {

                    --hero-height: var(--tabl-hero-height);
                    --hero-text-width: var(--tabl-hero-text-width);
                }

                @include sf-media($from: desk) {

                    --hero-height: var(--desk-hero-height);
                    --hero-text-width: var(--desk-hero-text-width);
                }
                
                min-height: var(--hero-height);
                max-width: var(--hero-text-width);
                
                h1, h3 {

                    text-shadow: 0 0 0.5rem rgba(0,0,0,0.15);
                }
            }
        }
    }
}
