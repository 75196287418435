.page-block-content {

    .content-block {

        &.simple-image-block, &.square-image-block, &.active-image-block {

            --image-corner-radius: 0.5rem;

            @include sf-media($from: tabp) {

                --image-corner-radius: 0.75rem;
            }
        }
        
        &.simple-image-block, &.square-image-block {
            
            [sf-image-wrapper] .caption {

                position: absolute;
                bottom: 1rem;
                left: 1rem;
                background-color: rgba(0, 0, 0, 0.35);
                padding: 0.5rem 0.75rem;
                border-radius: 0.35rem;

                &, & strong {
                    font-size: 1rem;
                    color: var(--sf-color-white);
                }
            }
        }

        &#footer-copyright {

            text-align: center;

            @include sf-media($from: tabp) {

                text-align: left;
            }
        }

        &.page-collection-grid-block {
            
            background-color: transparent;
            
            .panel {

                background-color: var(--sf-color-light);
            }
        }

        &.page-collection-block, &.proplete-strong-questionnaire-block {

            .panel {

                border-radius: 0.5rem;

                @include sf-media($from: tabp) {

                    border-radius: 0.75rem;
                }
            }
        }
        
        &.image-heading-block {

            --subheading-color: var(--sf-color-secondary);
        }

        &.feature-panel-block {

            .feature-panel {

                border-radius: 0.5rem;

                @include sf-media($from: tabp) {

                    border-radius: 0.75rem;
                }
            }            
        }

        &.stack-block {
            
            &#rounded-corners {

                border-radius: 0.5rem;
                min-height: 30rem;
                overflow: hidden;

                @include sf-media($from: tabp) {

                    border-radius: 0.75rem;
                    min-height: 100%;
                }
            }
        }

        &.rounded-panel-block {

            &#top-aligned {

                .rounded-panel {

                    & > [sf-grid] {

                        align-content: start;
                        height: auto;
                    }
                }
            }
        }        
    }
}

.grecaptcha-badge { visibility: hidden; }
