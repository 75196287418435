.page-block-content {

    .content-block {

        &.account-portal-block {

            .account-sidebar {

                a[role="button"].current {
                    user-select: none;
                    cursor: default;
                    opacity: 0.5;

                    &:hover {
                        background-color: var(--button-color);
                        border-color: var(--button-outline-color);
                        color: var(--button-text-color);
                    }
                }

                hr.menuLine {
                    margin-bottom: 0;
                }

                @include sf-media($upto: tabp) {
                    .mobile-view {
                        display: block;
                    }
                    .desktop-view {
                        display: none;
                    }
                }

                @include sf-media($from: tabp) {
                    .mobile-view {
                        display: none;
                    }
                    .desktop-view {
                        display: block;
                    }
                }
            }
        }
    }
}
