.page-block-content {

    .content-block {

        &.square-image-block {

            position: relative;
            
            // Base Overrides

            --background-color: transparent;

            // Custom

            --mobile-image-corner-radius: var(--image-corner-radius);
            --mobile-image-width: var(--image-width);
            --mobile-image-alignment: var(--image-alignment);
            
            --image-corner-radius: 0.25rem;
            --image-width: 100%;
            --image-alignment: left;
            
            --zero-margin: 0;
            --phab-margin: var(--zero-margin);
            --tabp-margin: var(--phab-margin);
            --tabl-margin: var(--tabp-margin);
            --note-margin: var(--tabl-margin);
            --desk-margin: var(--note-margin);
            --elas-margin: var(--desk-margin);
            --margin: var(--zero-margin);

            @include sf-media($from: phab) {
                --margin: var(--phab-margin);
            }

            @include sf-media($from: tabp) {
                --margin: var(--tabp-margin);
            }

            @include sf-media($from: tabl) {
                --margin: var(--tabl-margin);
            }

            @include sf-media($from: note) {
                --margin: var(--note-margin);
            }

            @include sf-media($from: desk) {
                --margin: var(--desk-margin);
            }

            @include sf-media($from: elas) {
                --margin: var(--elas-margin);
            }

            .cb-margin-wrapper {

                font-size: 0;
                text-align: var(--mobile-image-alignment);

                @include sf-media($from: tabp) {
                    text-align: var(--image-alignment);
                }
            }
            
            [sf-image-wrapper] {

                --zero-width: var(--mobile-image-width);
                --phab-width: var(--zero-width);
                --tabp-width: var(--image-width);
                --tabl-width: var(--tabp-width);
                --note-width: var(--tabl-width);
                --desk-width: var(--note-width);
                --elas-width: var(--desk-width);

                --max-width: var(--zero-width);
                --zero-side-margins: 0;
                --phab-side-margins: 0;

                @include sf-media($from: phab) { --max-width: var(--phab-width); }
                @include sf-media($from: tabp) { --max-width: var(--tabp-width); }
                @include sf-media($from: tabl) { --max-width: var(--tabl-width); }
                @include sf-media($from: note) { --max-width: var(--note-width); }
                @include sf-media($from: desk) { --max-width: var(--desk-width); }
                @include sf-media($from: elas) { --max-width: var(--elas-width); }
                
                position: relative;
                display: inline-block;
                max-width: var(--max-width);
                width: var(--max-width);
                
                overflow: hidden;
                
                .play-button-overlay {
                    position: absolute;
                    font-size: 5rem;
                    color: var(--sf-color-white);
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
                
                &.pointer {
                    
                    cursor: pointer;
                }
                
                img {
                    margin: var(--margin);
                    width: 100%;
                    border-radius: var(--mobile-image-corner-radius);

                    @include sf-media($from: tabp)
                    {
                        border-radius: var(--image-corner-radius);
                    }
                }
                
                & > a, & > img {

                    @include sf-media($from: tabp) {

                        &.mobile-only {

                            display: none;
                        }
                    }

                    @include sf-media($upto: tabp) {

                        &.not-mobile {

                            display: none;
                        }
                    }
                }
                
                .caption {

                    font-size: 0.75rem;
                    margin-top: 0.75rem;
                }
            }
        }
    }
}