/**
 * @nuclide Responsive Video Container
 * @section Functions > Media
 * @modifiers
 *  sf-video-container{} - Responsive video container
 * @markup
 *  .video-container {
 *    @include sf-video-container();
 *  }
 */
@mixin sf-video-container {

    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;

    iframe, object, embed {

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
