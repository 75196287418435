.page-block-content {

    .content-block {

        &.proplete-strong-footer-block {

            .learn-more {

                margin-bottom: 0;
                color: var(--accent-color);
                font-weight: 400;
                line-height: 1;

                a:not([role="button"]) {
                    font-weight: 400;
                    color: var(--accent-color);
                }
            }
            
            a:not([role="button"]) {
                
                text-decoration: none;
                
                &:hover {

                    text-decoration: underline;
                }
            }

            .social-icons-grid {

                display: flex;
                flex-wrap: wrap;
                position: relative;
                width: max-content;
                margin: auto;
                margin-bottom: 0.25rem;

                @include sf-media($from: tabp) {

                    margin: 0;
                    margin-bottom: 0.25rem;
                }

                img {
                    max-width: 1.5rem;
                    max-height: 1.5rem;
                }
            }

            p {
                
                &.footer-logo {

                    margin-bottom: 0.5rem;

                    img {
                        max-width: 16rem;
                    }
                }
                
                &.copyright {
                    
                    font-size: 0.9rem;
                    opacity: 0.5;
                }
            }
        }
    }
}
