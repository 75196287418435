.page-block-content {

    .content-block {

        &.theme-switch-block {

            // Base Overrides

            // Custom

            --charm-fg-color: var(--color);
            --charm-bg-color: var(--background-color);

            --selected-charm-fg-color: var(--background-color);
            --selected-charm-bg-color: var(--color);

            font-size: 0;
            line-height: 0;
            white-space: nowrap;

            .charm-wrapper {

                text-align: center;

                span, i {
                    font-size: 0.75rem;
                    line-height: 1;
                }

                div.charm-label {

                    position: relative;
                    display: inline-block;
                    height: 1.5rem;
                    margin-left: 0.75rem;

                    span {
                        display: inline-block;
                        position: relative;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }

                a {
                    position: relative;
                    display: inline-block;
                    height: 1.5rem;
                    width: 0.75rem;
                    border-radius: 50%;

                    font-size: 0;
                    line-height: 0;
                    text-align: initial;

                    background-color: var(--charm-bg-color);
                    color: var(--charm-fg-color);

                    transition: none;

                    & + a {
                        margin-left: 0.75rem;
                    }

                    &[selected] {

                        height: 1.5rem;
                        width: 1.5rem;
                        border-radius: 50%;
                        background-color: var(--selected-charm-bg-color);
                        color: var(--selected-charm-fg-color);
                    }

                    i {
                        position: relative;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        text-align: center;
                    }
                }
            }
        }
    }
}
