.page-block-content {

    .content-block {

        &.text-ribbon-block {

            // Base Overrides

            --zero-side-margins: 0 !important;
            --phab-side-margins: 0 !important;
            --tabp-side-margins: 0 !important;
            --tabl-side-margins: 0 !important;
            --note-side-margins: 0 !important;
            --desk-side-margins: 0 !important;
            --elas-side-margins: 0 !important;

            --zero-top-margin: 0 !important;
            --phab-top-margin: 0 !important;
            --tabp-top-margin: 0 !important;
            --tabl-top-margin: 0 !important;
            --note-top-margin: 0 !important;
            --desk-top-margin: 0 !important;
            --elas-top-margin: 0 !important;

            --zero-bottom-margin: 0 !important;
            --phab-bottom-margin: 0 !important;
            --tabp-bottom-margin: 0 !important;
            --tabl-bottom-margin: 0 !important;
            --note-bottom-margin: 0 !important;
            --desk-bottom-margin: 0 !important;
            --elas-bottom-margin: 0 !important;

            --zero-elastic-width: var(--zero-full-width) !important;
            --phab-elastic-width: var(--phab-full-width) !important;
            --tabp-elastic-width: var(--tabp-full-width) !important;
            --tabl-elastic-width: var(--tabl-full-width) !important;
            --note-elastic-width: var(--note-full-width) !important;
            --desk-elastic-width: var(--desk-full-width) !important;
            --elas-elastic-width: var(--elas-full-width) !important;

            --carousel-width: 20.5rem;

            @include sf-media($from: phab) {
                --carousel-width: 22.5rem;
            }

            @include sf-media($from: tabp) {
                --carousel-width: 50rem;
            }

            @include sf-media($from: tabl) {
                --carousel-width: 64rem;
            }

            @include sf-media($from: note) {
                --carousel-width: 69rem;
            }

            @include sf-media($from: desk) {
                --carousel-width: 69rem;
            }

            @include sf-media($from: elas) {
                --carousel-width: 69rem;
            }

            // Custom

            --light-bg-color: transparent;
            --dark-bg-color: transparent;
            --bg-color: var(--sf-color-always-white);

            .cb-overlay {
                background-color: var(--bg-color);

                .cb-elastic-wrapper {

                    width: var(--carousel-width);
                    overflow: hidden;

                    .cb-margin-wrapper {
                        border-radius: 1rem;
                        overflow: hidden;
                    }
                }
            }
            
            .left-nav-charm, .right-nav-charm {

                position: absolute;
                width: 1rem;
                height: 3rem;
                line-height: 0;
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;
                z-index: 999;
                text-align: center;

                i {

                    position: absolute;
                    font-size: 2rem;
                    line-height: 4rem;
                    top: 50%;
                    left: 50%;
                    width: 100%;
                    transform: translate(-50%,-50%);
                    color: var(--sf-color-primary);
                }
                
                @include sf-media($from: tabp) {

                    width: 2rem;
                    height: 3rem;
                    border-radius: 2.5rem;
                    //background-color: rgba(0, 0, 0, 0.05);
                    top: 50%;
                    line-height: 4rem;

                    i {
                        font-size: 2rem;
                    }

                    &:hover:not(.disabled) {
                        //background-color: var(--color);
                        //color: var(--bg-color);
                    }
                }

                &.hidden {
                    display: none;
                }
            }

            .left-nav-charm {

                left: 0.5rem;

                @include sf-media($from: tabp) {

                    left: 2rem;
                }

                @include sf-media($from: note) {

                    left: calc((var(--carousel-width) - 60rem) / 2);
                }
            }

            .right-nav-charm {

                right: 0.5rem;

                @include sf-media($from: tabp) {

                    right: 2rem;
                }

                @include sf-media($from: note) {

                    right: 4rem;
                }
            }
            
            .carousel {

                position: relative;
                background-color: var(--bg-color);
                width: max-content;
                white-space: nowrap;
                font-size: 0;
                
                .slide {

                    position: relative;
                    display: inline-block;
                    width: var(--carousel-width);
                    white-space: normal;
                    font-size: 1rem;
                    vertical-align: middle;

                    .slide-inner {

                        position: relative;
                        left: 50%;
                        transform: translateX(-50%);
                        padding-top: 1rem;
                        padding-bottom: 1rem;
                        
                        .text-content {

                            --charm-margin: 0.5rem;
                            --charm-width: 1rem;

                            @include sf-line-clamp($lines: 2);
                            
                            padding: 0 1rem;
                            max-width: calc(var(--carousel-width) - (var(--charm-margin) * 2) - (var(--charm-width) * 2));
                            margin: auto;
                            text-align: center;
                            text-wrap: pretty;
                            color: var(--sf-color-primary);

                            @include sf-media($from: tabp) {
                                @include sf-line-clamp($lines: 1);
                                --charm-margin: 2rem;
                                --charm-width: 2rem;
                            }

                            @include sf-media($from: note) {

                                --charm-margin: 4rem;
                            }

                            .icon {
                                max-width: 4rem;
                                max-height: 4rem;
                            }
                            
                            h3 {
                                
                                --h3-margin-top: 1rem;
                                
                                margin-bottom: 0;
                                
                                & + p {
                                    
                                    margin-top: 1rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
