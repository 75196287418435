.page-block-content {

    .content-block {

        &.new-zoia-page-hero-block {
            --heading-color: var(--sf-color-white);
            --bottom-margin: 0;
            --top-margin: 0;
            position: relative;
            overflow: hidden;

            // Override the default global settings
            h1, h2, h3, h4, h5, h6 {
                text-transform: unset;
            }
            
            background: linear-gradient(90deg, var(--left-gradient-color) 0%, var(--right-gradient-color) 100%);
            
            .hero-grid {
                --sf-cols: 1fr;
                --sf-tabp-cols: 28rem 1fr;
                --sf-col-gap: 6rem;
                --sf-row-gap: 2rem;

                .text-content {
                    padding: 2rem 1.25rem 0 1.25rem;

                    @include sf-media($from: tabp) {
                        padding: 2rem 0;
                    }
                    
                    h1, h3 {
                        margin: 0;
                        text-wrap: pretty;
                    }
                    
                    h1 {
                        margin-bottom: 1rem;
                    }
                }
                
                .image-content {

                    img {
                        
                    }
                }
            }
        }
    }
}
