.customer-information-partial {

    [hidden], [hidden='true'] {
        display: none !important;
    }

    &, [sf-form] {

        [sf-validation-message] {

            color: var(--sf-color-danger);
        }
    }
    
    p {
        text-wrap: pretty;
    }
}
