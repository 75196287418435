/**
 * @nuclide Remove Media Gaps
 *
 * Remove gap between elements and the bottom of their containers.
 * @section Layout > Media
 * @markup
 *  <audio />
 *  <canvas />
 *  <img />
 *  <svg />
 *  <video />
 */
audio,
canvas,
img,
video {

    vertical-align: middle;
}

/**
 * @nuclide Fluid Images
 *
 * Responsive images.
 * @section Layout > Media
 * @markup
 *  <img />
 *  <object />
 *  <svg />
 */
img, object {

    position: relative;
    width: 100%;
    max-width: 100%;
    -ms-interpolation-mode: bicubic;
}

/**
 * @nuclide Auto SVG sizing
 * @section Layout > Media
 * @markup
 *  <span sf-svg>
 *    <svg />
 *  </span>
 */
[sf-svg] {

    & > svg {

        vertical-align: middle;
        position: relative;
        width: 100%;
        height: auto;
        max-width: 100%;
        -ms-interpolation-mode: bicubic;
    }
}

/**
 * @nuclide Non-fluid object images
 * @section Layout > Media
 * @modifiers
 *  [width, height] - Non-fluid images if you specify `width` and/or `height` attributes.
 * @markup
 *  <object width="100"></object>
 *  <img width="100"></object>
 */
object[width],
object[height],
img[width],
img[height] {

    width: auto;
    max-width: none;
}

/**
 * @nuclide Inline-block span images and icons
 * @section Layout > Media
 * @modifiers
 *  [role="img", role="icon"] - Icons in spans use inline-block and no line height.
 * @markup
 *  <span role="img"></span>
 *  <span role="icon"></span>
 */
span[role="img"], span[role="icon"] {

    display: inline-block;
    line-height: 0;
}

/**
 * @nuclide SVG in object tag
 * @section Layout > Media
 * @modifiers
 *  [type="image/svg+xml"] - Inline-block for object tag SVG images.
 * @markup
 *  <object type="image/svg+xml"></object>
 */
object[type="image/svg+xml"] {

    display: inline-block;
    line-height: 0;
    pointer-events: none;
}

/**
 * @structure Responsive Image
 * @section Layout > Media
 * @modifiers
 *  [sf-image-wrapper] - Responsive images with optional caption.
 * @markup
 *  <div sf-image-wrapper>
 *    <img />
 *    <small>Caption</small>
 *  </div>
 */
[sf-image-wrapper] {

    @include sf-media($upto: tabp) {

        margin-left: calc(var(--zero-side-margins) * -1);
        margin-right: calc(var(--zero-side-margins) * -1);

        img {

            max-width: 100vw;
            width: 100vw;
        }
    }

    @include sf-media($from: zero, $upto: phab) {

        margin-left: calc(var(--phab-side-margins) * -1);
        margin-right: calc(var(--phab-side-margins) * -1);
    }

    @include sf-media($from: tabp) {

        img {

            border-radius: 0.25rem;
            overflow: hidden;
        }
    }

    small {

        display: block;
        margin-top: 0.75rem;
        line-height: 1.3;

        @include sf-media($upto: tabp) {

            margin-left: var(--zero-side-margins);
            margin-right: var(--zero-side-margins);
        }

        @include sf-media($from: zero, $upto: phab) {

            margin-left: var(--phab-side-margins);
            margin-right: var(--phab-side-margins);
        }
    }
}

/**
 * @structure Responsive Video
 * @section Layout > Media
 * @modifiers
 *  [sf-video-wrapper, sf-video] - Responsive video with optional caption.
 * @markup
 *  <div sf-video-wrapper>
 *    <video />
 *    <small>Caption</small>
 *  </div>
 *  <div sf-video-wrapper>
 *    <div sf-video><iframe /></div>
 *    <small>Caption</small>
 *  </div>
 */
[sf-video-wrapper] {

    @include sf-media($upto: tabp) {

        margin-left: calc(var(--block-lr-padding) * -1);
        margin-right: calc(var(--block-lr-padding) * -1);
    }

    [sf-video], video {

        position: relative;
        background-color: #000000;
        overflow: hidden;
        min-width: 100%;
        max-width: 100%;

        @include sf-media($from: tabp) {
            border-radius: 0.25rem;
            overflow: hidden;
        }
    }

    [sf-video] {

        @include sf-video-container();
    }

    small {

        display: block;
        margin-top: 0.75rem;

        @include sf-media($upto: tabp) {

            margin-left: var(--block-lr-padding);
            margin-right: var(--block-lr-padding);
        }
    }
}

/**
 * @structure Responsive Audio
 * @section Layout > Media
 * @modifiers
 *  [sf-audio-wrapper, sf-audio] - Responsive audio with optional caption.
 * @markup
 *  <div sf-audio-wrapper>
 *    <audio />
 *    <small>Caption</small>
 *  </div>
 *  <div sf-audio-wrapper>
 *    <audio />
 *    <small>Caption</small>
 *  </div>
 */
[sf-audio-wrapper] {

    [sf-audio], audio {

        position: relative;
        overflow: hidden;
        min-width: 100%;
        max-width: 100%;
    }

    small {

        display: block;
        margin-top: 0.75rem;
    }
}
