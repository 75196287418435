/**
 * @nuclide Button
 *
 * Default styles for a button.
 * @section Styles > buttons
 * @markup
 *  <button />
 *  <input type="button" />
 *  <input type="submit" />
 *  <input type="cancel" />
 *  <a role="button"></a>
 */
button, a[role="button"], [sf-button] {

    display: inline-block;
    margin: 0;
    border: 0;
    padding: 0 var(--font-size);
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    background-color: var(--sf-color-buttons);
    color: var(--sf-color-buttons-contrast);
    border-radius: 0.2rem;
    cursor: pointer;
    font-size: 0;
    line-height: 1.3rem;
    font-weight: var(--button-font-weight);
    width: 100%;
    font-family: var(--font-family-forms);
    user-select: none;

    @include sf-media($from: tabp) {

        white-space: nowrap;
        width: auto;
    }

    & > * {
        
        padding: 0.6rem 0;
        user-select: none;
    }
    
    &:hover,
    &:focus {

        text-decoration: none;
    }

    &[disabled] {

        cursor: not-allowed;
        opacity: 0.4;
    }
    
    & > * {
        
        display: inline-block;
    }

    span, i {

        font-size: var(--font-size);
        line-height: calc(var(--font-size) * 1.2);
    }
    
    i + span {
        margin-left: 0.5rem;
    }

    span + i {
        margin-left: 0.5rem;
    }
}

input[type="button"], input[type="submit"], input[type="cancel"] {

    display: inline-block;
    margin: 0;
    border: 0;
    padding: 0.6rem 1rem;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    white-space: nowrap;
    background-color: var(--sf-color-buttons);
    color: var(--sf-color-buttons-contrast);
    border-radius: 0.2rem;
    cursor: pointer;
    font-size: 1rem;
    line-height: 1.3rem;
    width: 100%;
    font-family: var(--font-family-forms);
    font-weight: var(--button-font-weight);
    user-select: none;
    //border: solid var(--sf-line-width) var(--sf-color-buttons-contrast);

    @include sf-media($from: tabp) {

        width: auto;
    }

    &:hover,
    &:focus {

        text-decoration: none;
    }

    &[disabled] {

        cursor: not-allowed;
        opacity: 0.4;
    }

    & > * {

        display: inline-block;
    }

    i + span {
        margin-left: 0.5rem;
    }

    span + i {
        margin-right: 0.5rem;
    }
}
