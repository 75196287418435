.page-block-content {

    .content-block {

        &.page-collection-header-block {

            // Overrides

            // Custom

            --tag-bg-color: var(--sf-color-light);
            --tag-fg-color: var(--sf-color-black);
            --tag-hover-bg-color: var(--sf-color-light);
            --tag-hover-fg-color: var(--sf-color-black);
            --tag-selected-bg-color: var(--sf-color-light);
            --tag-selected-fg-color: var(--sf-color-black);
            
            .heading-bar {

                text-align: center;
                padding-bottom: 1.25rem;
                border-bottom: solid 1px var(--sf-color-secondary);
                margin-bottom: 1.5rem;

                h1 {

                    font-size: 2rem;
                    margin-bottom: 0;
                    
                    & + p {
                        
                        margin-top: 1rem;
                    }
                }
            }
            
            .tag-bar {

                font-size: 0;
                line-height: 0;

                display: flex;
                flex-wrap: wrap;
                gap: 0.5rem;
                
                @include sf-media($from: tabp) {

                    justify-content: center;
                }
                
                .tag {

                    width: auto;
                    background-color: var(--tag-bg-color);
                    color: var(--tag-fg-color);

                    & + .tag {
                        
                        //margin-left: 0.5rem;
                        //margin-bottom: 0.5rem;
                    }
                    
                    &:last-child {
                        
                        margin-right: var(--side-margins);

                        @include sf-media($from: tabp) {
                            margin-right: 0;
                        }                        
                    }

                    &:hover {

                        background-color: var(--tag-hover-bg-color);
                        color: var(--tag-hover-fg-color);
                    }
                    
                    &.selected {

                        background-color: var(--tag-selected-bg-color);
                        color: var(--tag-selected-fg-color);
                    }

                    span {
                        
                        font-weight: 400;
                    }
                    
                    i {
                        font-size: 0.9rem;
                        line-height: 1.3rem;
                    }
                }
            }
            
            .search-bar {
                
                position: relative;
                max-height: 9rem;
                overflow: hidden;

                @include sf-animate(
                    $property: max-height,
                    $delay: 0,
                    $duration: 0.15s,
                    $kind: ease
                );

                @include sf-media($from: tabp) {

                    max-height: 5rem;
                }
                
                &.closed {
                    
                    max-height: 0;
                }
                
                .search-inner {
                
                    position: relative;
                    padding: 1.25rem 0;

                    @include sf-media($from: tabp) {

                        left: 50%;
                        transform: translateX(-50%);
                        max-width: 30rem;
                    }
                }
            }
        }
    }
}
