.page-block-content {

    .content-block {

        &.page-collection-grid-block {

            // Overrides

            background-color: var(--sf-color-light);
            color: var(--sf-color-dark);
            
            // Custom

            .title-excerpt-bar {

                text-align: center;
                padding-bottom: 1rem;
                border-bottom: solid 1px var(--sf-color-medium);
                margin-bottom: 2rem;

                h2, p {

                    max-width: 40rem;
                    position: relative;
                    left: 50%;
                    transform: translateX(-50%);
                }
                
                h2, p:last-child {

                    margin-bottom: 1rem;
                }

                @include sf-media($from: tabp) {

                    padding-bottom: 2rem;
                    border-bottom: solid 1px var(--sf-color-secondary);
                    margin-bottom: 3rem;
                }
            }

            .not-found {

                text-align: center;

                h3 {
                    margin-bottom: 0.5rem;
                }
            }
            
            .panel {

                display: block;
                background-color: var(--sf-color-white);
                color: var(--sf-color-black);
                padding: 1.5rem;
                overflow: hidden;
                border-radius: 0.35rem;
                text-decoration: none;
                height: 100%;

                .image, h5, .excerpt {

                    text-align: center;
                }
                
                .image {

                    overflow: hidden;
                    border-radius: 50%;
                    margin: 0 3rem;
                }

                h5 {

                    margin-top: 1rem;
                    margin-bottom: 0.5rem;
                    font-weight: 600;

                    @include sf-line-clamp($lines: 2);
                }

                .excerpt {
                    
                    font-size: 0.9rem;
                    @include sf-line-clamp($lines: 3);
                }
            }
        }
    }
}
