.page-block-content {

    .content-block {

        &.image-heading-block {

            --subheading-color: var(--color);
            
            .main-grid {

                .heading {

                    margin-top: 0;
                    margin-bottom: 1rem;
                }

                .subheading {

                    margin-top: 1rem;
                    margin-bottom: 0;
                    color: var(--subheading-color);
                }

                img {

                    overflow: hidden;
                    border-radius: 0.25rem;
                }
            }                
        }
    }
}
