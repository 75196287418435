.page-block-content {

    .content-block {

        &.account-portal-block {

            .account-info-partial {

                h1, h2, h3, h4, h5 {
                    
                    text-wrap: pretty;
                }
                
                .save-result {

                    margin-top: 1rem;
                    font-weight: bold;
                    color: var(--sf-color-success);

                    &:empty {
                        display: none;
                    }
                }
                
                [hidden], [hidden="true"] {

                    display: none;
                }

                [sf-form] {

                    [sf-validation-message] {

                        color: var(--sf-color-danger);
                    }
                }                
            }
        }
    }
}