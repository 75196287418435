$sfumato-version: "4.0";

/**
 * @atom SFUMATO Version
 * @section Configuration
 * @modifiers
 *  span#sfumato-version[role="status"] - Will set the content of this item to the SFUMATO version text.
 * @markup
 *   <span id="sfumato-version" role="status"></span>
 */
span#sfumato-version[role="status"]::after {

    content: quote($sfumato-version);
}
