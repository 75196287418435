.page-block-content {

    .content-block {

        &.formatted-text-block {

            // Base Overrides

            --background-color: transparent;
            
            // Custom
            
            h1, h2, h3, h4, h5, h6 {
                
                &.primary {
                    
                    color: var(--sf-color-primary);
                }

                &.secondary {

                    color: var(--sf-color-secondary);
                }

                &.tertiary {

                    color: var(--sf-color-tertiary);
                }
            }
        }
    }
}
