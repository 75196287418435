.page-block-content {

    .content-block {

        &.heading-block {

            // Base Overrides

            --background-color: transparent;
            
            // Custom
            
            h1, h2, h3, h4, h5, h6 {

                &.center {
                    text-align: center;
                }
            }
            
            .publish-info {

                margin-bottom: 0.5rem;
                
                .publish-date {
                    opacity: 0.5;
                }

                .divider {
                    display: inline-block;
                    margin: 0 0.35rem;
                    opacity: 0.35;
                }
            }
        }
    }
}
