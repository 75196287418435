/**
 * @nuclide Animation
 * @section Mixins > Animation
 * @modifiers
 *  sf-animate() - Establish CSS animation properties for an element.
 * @markup
 *  @include sf-animate(
 *    $property: all,
 *    $delay: 0,
 *    $duration: 0.5s,
 *    $kind: ease);
 */
@mixin sf-animate (
    $property: all,
    $delay: 0,
    $duration: 0.5s,
    $kind: ease
) {
    -moz-transition-property: $property;
    -o-transition-property: $property;
    -webkit-transition-property: $property;
    transition-property: $property;

    -moz-transition-duration: $duration;
    -o-transition-duration: $duration;
    -webkit-transition-duration: $duration;
    transition-duration: $duration;

    -moz-transition-timing-function: $kind;
    -o-transition-timing-function: $kind;
    -webkit-transition-timing-function: $kind;
    transition-timing-function: $kind;

    -moz-animation-delay: $delay;
    -o-animation-delay: $delay;
    -webkit-animation-delay: $delay;
    animation-delay: $delay;
}
