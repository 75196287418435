.page-block-content {

    .content-block {

        &.file-attachment-block {

            // Base Overrides

            
            // Custom
            
            .file-icon {
                
                line-height: 0;
                font-size: 0;
                
                i {
                    font-size: 3rem;
                    line-height: 1;
                }
            }
            
            .file-item {
             
                h6, p {
                    
                    line-height: 1.3;
                    margin-bottom: 0.25rem;
                }
                
                p {

                    font-size: 0.9rem;
                    
                    span {
                        display: inline-block;
                        margin:  0 0.25rem;
                        opacity: 0.35;
                    }
                    
                    &:last-child {
                        
                        margin-bottom: 0;
                    }
                }
            }            
        }
    }
}
