.page-block-content {

    .content-block {

        &.form-block {

            [sf-button-validation-message] {

                display: block;
                color: var(--sf-color-danger);
                padding-top: 1rem;
                font-weight: bold;
            }
            
            [sf-radios] {
                ul {
                    li {
                        padding-left: 0;
                    }
                }
            }

            [sf-filebox] {

                [sf-wrapper] {
                    padding: 0.5rem 0.65rem;

                    [sf-file-picker] {
                        cursor: pointer;

                        i {
                            font-size: 1.25rem;
                            color: var(--sf-color-file-picker-icon);
                        }

                        [sf-progress-wrapper] {

                            [sf-progress-bar-wrapper] {
                                font-size: 0;
                                line-height: 0;

                                [sf-progress-bar] {
                                    height: 0.5rem;
                                    background-color: var(--sf-color-primary);
                                    border-radius: 0.1rem;
                                    overflow: hidden;
                                    width: 100%;
                                    min-width: 0.5rem;
                                }

                                [sf-percentage] {
                                    white-space: nowrap;
                                    font-size: 0.75rem;
                                    font-weight: 500;
                                    line-height: 1;
                                }
                            }
                        }
                    }

                    [sf-file-prompt] {

                        @include sf-line-clamp($lines: 1);
                        font-size: 0.75rem;
                        line-height: 1.4;
                    }
                }
            }

            [sf-file-pick-list] {
                margin-top: 0.5rem;

                &:not(:empty) {
                    margin-top: 1rem;
                }

                [sf-file-name], [sf-file-size] {

                    @include sf-line-clamp($lines: 1);
                    line-height: 1.4;
                    font-size: 0.75rem;
                }

                [sf-file-size] {
                    opacity: 0.5;
                }

                [sf-delete-file-icon] {
                    cursor: pointer;
                    color: var(--sf-color-danger);
                    font-size: 0.75rem;
                }
            }
        }
    }
}
