.page-block-content {

    .content-block {

        &.accordion-item-block {

            // Base Overrides

            --background-color: transparent;
            
            // Custom
            
            .cta-panel {

                border: solid 1px var(--color-secondary);
                padding: 1rem 1.25rem;
                border-radius: 0.75rem;
                cursor: pointer;
                
                .panel-content {

                    display: grid;
                    grid-template-rows: 0fr;
                    transition: grid-template-rows 250ms;

                    &.open {
                        grid-template-rows: 1fr;
                    }
                    
                    .panel-content-inner {

                        overflow: hidden;

                        .panel-content-inner-wrapper {
                            
                            padding-top: 1rem;
                        }
                    }
                }
            }
        }
    }
}
