.page-block-content {

    .content-block {

        &.rounded-panel-block {

            // Overrides

            --zero-grid-cols: 1fr;
            --phab-grid-cols: var(--zero-grid-cols);
            --tabp-grid-cols: var(--phab-grid-cols);
            --tabl-grid-cols: var(--tabp-grid-cols);
            --note-grid-cols: var(--tabl-grid-cols);
            --desk-grid-cols: var(--note-grid-cols);
            --elas-grid-cols: var(--desk-grid-cols);

            --zero-grid-child-gap: 2rem;
            --phab-grid-child-gap: var(--zero-grid-child-gap);
            --tabp-grid-child-gap: var(--phab-grid-child-gap);
            --tabl-grid-child-gap: var(--tabp-grid-child-gap);
            --note-grid-child-gap: var(--tabl-grid-child-gap);
            --desk-grid-child-gap: var(--note-grid-child-gap);
            --elas-grid-child-gap: var(--desk-grid-child-gap);

            --zero-grid-col-gap: 0;
            --phab-grid-col-gap: var(--zero-grid-col-gap);
            --tabp-grid-col-gap: 2rem;
            --tabl-grid-col-gap: var(--tabp-grid-col-gap);
            --note-grid-col-gap: var(--tabl-grid-col-gap);
            --desk-grid-col-gap: var(--note-grid-col-gap);
            --elas-grid-col-gap: var(--desk-grid-col-gap);

            --zero-grid-row-gap: 1rem;
            --phab-grid-row-gap: var(--zero-grid-row-gap);
            --tabp-grid-row-gap: var(--phab-grid-row-gap);
            --tabl-grid-row-gap: var(--tabp-grid-row-gap);
            --note-grid-row-gap: var(--tabl-grid-row-gap);
            --desk-grid-row-gap: var(--note-grid-row-gap);
            --elas-grid-row-gap: var(--desk-grid-row-gap);

            --zero-panel-corner-radius: 0.25rem;
            --phab-panel-corner-radius: var(--zero-panel-corner-radius);
            --tabp-panel-corner-radius: var(--phab-panel-corner-radius);
            --tabl-panel-corner-radius: var(--tabp-panel-corner-radius);
            --panel-corner-radius: var(--zero-panel-corner-radius);

            --panel-background-color: transparent;

            --zero-panel-side-padding: 1.5;
            --phab-panel-side-padding: var(--zero-panel-side-padding);
            --tabp-panel-side-padding: var(--phab-panel-side-padding);
            --tabl-panel-side-padding: var(--tabp-panel-side-padding);
            --panel-side-padding: var(--zero-panel-side-padding);

            --zero-panel-vertical-padding: 2rem;
            --phab-panel-vertical-padding: var(--zero-panel-vertical-padding);
            --tabp-panel-vertical-padding: var(--phab-panel-vertical-padding);
            --tabl-panel-vertical-padding: var(--tabp-panel-vertical-padding);
            --panel-vertical-padding: var(--zero-panel-vertical-padding);

            border-radius: var(--panel-corner-radius);

            @include sf-media($from: phab) {

                --panel-side-padding: var(--phab-panel-side-padding);
                --panel-vertical-padding: var(--phab-panel-vertical-padding);
                --panel-corner-radius: var(--phab-panel-corner-radius);
            }

            @include sf-media($from: tabp) {

                --panel-side-padding: var(--tabp-panel-side-padding);
                --panel-vertical-padding: var(--tabp-panel-vertical-padding);
                --panel-corner-radius: var(--tabp-panel-corner-radius);
            }

            @include sf-media($from: tabl) {

                --panel-side-padding: var(--tabl-panel-side-padding);
                --panel-vertical-padding: var(--tabl-panel-vertical-padding);
                --panel-corner-radius: var(--tabl-panel-corner-radius);
            }

            & > .cb-overlay > .cb-elastic-wrapper > .cb-margin-wrapper > .rounded-panel > [sf-grid] {

                --sf-cols: var(--zero-grid-cols); --sf-phab-cols: var(--phab-grid-cols); --sf-tabp-cols: var(--tabp-grid-cols); --sf-tabl-cols: var(--tabl-grid-cols); --sf-note-cols: var(--note-grid-cols); --sf-desk-cols: var(--desk-grid-cols); --sf-elas-cols: var(--elas-grid-cols);
                --sf-col-gap: var(--zero-grid-col-gap); --sf-phab-col-gap: var(--phab-grid-col-gap); --sf-tabp-col-gap: var(--tabp-grid-col-gap); --sf-tabl-col-gap: var(--tabl-grid-col-gap); --sf-note-col-gap: var(--note-grid-col-gap); --sf-desk-col-gap: var(--desk-grid-col-gap); --sf-elas-col-gap: var(--elas-grid-col-gap);
                --sf-row-gap: var(--zero-grid-row-gap); --sf-phab-row-gap: var(--phab-grid-row-gap); --sf-tabp-row-gap: var(--tabp-grid-row-gap); --sf-tabl-row-gap: var(--tabl-grid-row-gap); --sf-note-row-gap: var(--note-grid-row-gap); --sf-desk-row-gap: var(--desk-grid-row-gap); --sf-elas-row-gap: var(--elas-grid-row-gap);
                --sf-align-items: var(--zero-grid-align); --sf-phab-align-items: var(--phab-grid-align); --sf-tabp-align-items: var(--tabp-grid-align); --sf-tabl-align-items: var(--tabl-grid-align); --sf-note-align-items: var(--note-grid-align); --sf-desk-align-items: var(--desk-grid-align); --sf-elas-align-items: var(--elas-grid-align);
                --sf-vertical-align-items: var(--zero-grid-valign); --sf-phab-vertical-align-items: var(--phab-grid-valign); --sf-tabp-vertical-align-items: var(--tabp-grid-valign); --sf-tabl-vertical-align-items: var(--tabl-grid-valign); --sf-note-vertical-align-items: var(--note-grid-valign); --sf-desk-vertical-align-items: var(--desk-grid-valign); --sf-elas-vertical-align-items: var(--elas-grid-valign);
            }

            & > .cb-overlay > .cb-elastic-wrapper > .cb-margin-wrapper > .rounded-panel > [sf-grid] > [sf-grid-cell] {

                & > [sf-grid], & > [role="presentation"] > [sf-grid] {

                    --sf-row-gap: var(--zero-grid-child-gap); --sf-phab-row-gap: var(--phab-grid-child-gap); --sf-tabp-row-gap: var(--tabp-grid-child-gap); --sf-tabl-row-gap: var(--tabl-grid-child-gap); --sf-note-row-gap: var(--note-grid-child-gap); --sf-desk-row-gap: var(--desk-grid-child-gap); --sf-elas-row-gap: var(--elas-grid-child-gap);
                }
            }

            & > .cb-overlay > .cb-elastic-wrapper > .cb-margin-wrapper {

                --zero-side-margins: 0;
                --phab-side-margins: 0;
            }

            & > .cb-overlay,
            & > .cb-overlay > .cb-elastic-wrapper,
            & > .cb-overlay > .cb-elastic-wrapper > .cb-margin-wrapper,
            & > .cb-overlay > .cb-elastic-wrapper > .cb-margin-wrapper > .rounded-panel
            {
                height: var(--zero-grid-cell-height);

                @include sf-media($from: phab) {

                    height: var(--phab-grid-cell-height);
                }

                @include sf-media($from: tabp) {

                    height: var(--tabp-grid-cell-height);
                }

                @include sf-media($from: tabl) {

                    height: var(--tabl-grid-cell-height);
                }

                @include sf-media($from: note) {

                    height: var(--note-grid-cell-height);
                }

                @include sf-media($from: desk) {

                    height: var(--desk-grid-cell-height);
                }

                @include sf-media($from: elas) {

                    height: var(--elas-grid-cell-height);
                }
            }

            // Custom
            
            .rounded-panel {

                & > [sf-grid] {

                    --sf-zero-row-gap: var(--zero-grid-child-gap);
                    --sf-phab-row-gap: var(--phab-grid-child-gap);
                    --sf-tabp-row-gap: var(--tabp-grid-child-gap);
                    --sf-tabl-row-gap: var(--tabl-grid-child-gap);
                    --sf-note-row-gap: var(--note-grid-child-gap);
                    --sf-desk-row-gap: var(--desk-grid-child-gap);
                    --sf-elas-row-gap: var(--elas-grid-child-gap);

                    align-content: center;
                    height: 100%;                    
                }

                background-color: var(--panel-background-color);
                padding: var(--panel-vertical-padding) var(--panel-side-padding);
                border-radius: var(--panel-corner-radius);
            }
        }
    }
}
