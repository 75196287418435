.page-block-content {

    .content-block {

        &.latest-page-block {

            // Overrides

            // Custom

            .panel {

                img {

                    overflow: hidden;
                    border-radius: 0.25rem;
                    border: solid 1px rgba(0,0,0,0.07);
                }

                h3 {

                    margin-top: 0;
                    margin-bottom: 1rem;

                    @include sf-line-clamp($lines: 3);

                    @include sf-media($from: tabp) {

                        @include sf-line-clamp($lines: 2);
                    }                    
                }
                
                p {

                    &.excerpt {

                        @include sf-line-clamp($lines: 4);

                        @include sf-media($from: tabp) {

                            @include sf-line-clamp($lines: 4);
                        }

                        &:not(:last-child) {
                            margin-bottom: 1rem;
                        }
                    }
                    
                    &.categories {
                        
                        margin-bottom: 0.75rem;
                    }
                }

                .dateline {

                    margin-bottom: 1rem;
                    
                    span {

                        display: inline-block;
                        opacity: 0.4;

                        &.bar {
                            margin: 0 0.75rem;
                            opacity: 0.25;
                        }
                    }                    
                }
                
                a:not([role="button"]) {
                    text-decoration: none;
                    
                    &:hover {

                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
