.page-block-content {

    .content-block {

        &.featured-news-block {

            // Base Overrides

            --background-color: var(--sf-color-light);
            --color: var(--sf-color-dark);
            
            // Custom
            
            .title-excerpt-bar {
                
                text-align: center;
                padding-bottom: 1rem;
                border-bottom: solid 1px var(--sf-color-secondary);
                margin-bottom: 2rem;

                h2, p:last-child {
                    
                    margin-bottom: 1rem;
                }
            }
            
            .main-grid {

                @include sf-media($from: tabp) {

                    position: relative;
                    margin: auto;
                }
                
                h3 {
                    
                    margin-bottom: 0.75rem;    
                    
                    a {
                        text-decoration: none;
                        
                        &:hover {

                            text-decoration: underline;
                        }
                    }
                }

                .dateline {

                    span {

                        display: inline-block;
                        opacity: 0.4;

                        &.bar {
                            margin: 0 0.75rem;
                            opacity: 0.25;
                        }
                    }
                }
            }            
        }
    }
}
