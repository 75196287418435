// REQUIRED: Breakpoints; uses px blocks. Ensure that font sizes are set 
// for active breakpoints in the typography configuration.
$media-breakpoints: (

    // REQUIRED: Used internally to streamline detection of mobile first styles.
    zero: 0px, 

    // REQUIRED: Set the screen width for which a phab experience begins.
    // Standard value is 400px.
    phab: 400px, 
    
    // REQUIRED: Set the screen width for which a portrait tablet experience begins.
    // Standard value is 540px.
    tabp: 540px,

    // REQUIRED: Set the screen width for which a landscape tablet experience begins.
    // Standard value is 800px.
    tabl: 800px,

    // REQUIRED: Set the screen width for which a notebook-sized experience begins.
    // Standard value is 1280px.
    note: 1152px,

    // REQUIRED: Set the screen width for which a desktop-sized experience begins.
    // Standard value is 1280px.
    desk: 1440px,
        
    // REQUIRED: Page content will stop growing at this (elastic) width.
    // Standard value is 1440px.
    elas: 1920px
);

// REQUIRED: breakpoint variables
$zero-breakpoint: map-get($media-breakpoints, "zero");
$phab-breakpoint: map-get($media-breakpoints, "phab");
$tabp-breakpoint: map-get($media-breakpoints, "tabp");
$tabl-breakpoint: map-get($media-breakpoints, "tabl");
$note-breakpoint: map-get($media-breakpoints, "note");
$desk-breakpoint: map-get($media-breakpoints, "desk");
$elas-breakpoint: map-get($media-breakpoints, "elas");

:root {

  --phab-breakpoint: #{$phab-breakpoint};
  --tabp-breakpoint: #{$tabp-breakpoint};
  --tabl-breakpoint: #{$tabl-breakpoint};
  --note-breakpoint: #{$note-breakpoint};
  --desk-breakpoint: #{$desk-breakpoint};
  --elas-breakpoint: #{$elas-breakpoint};
}

// REQUIRED: Maximum number of table columns to support.
// Used by some mixins to generate table styles.
// $max-table-column-count: 10;

// REQUIRED: Media query expressions.
// Used by some mixins to generate proper media queries.
$media-features: (
    portrait:  '(orientation: portrait)',
    landscape: '(orientation: landscape)',
    at2x: '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)',
    at3x: '(-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi)'
);
