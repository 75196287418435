.page-block-content {

    .content-block {

        &.image-hero-block {

            position: relative;

            // Base Overrides

            --background-color: var(--sf-color-light);
            --corner-radius: 0.25rem;
            --overlay-bg-color: var(--sf-color-primary);
            --overlay-fg-color: var(--sf-color-white);
            --overlay-alignment: left;

            --mobile-overlay-padding: 0.75rem 1rem;
            --overlay-padding: var(--mobile-overlay-padding);

            // Custom

            .overlay {

                padding: var(--mobile-overlay-padding);
                background-color: var(--overlay-bg-color);
                color: var(--overlay-fg-color);
                text-align: var(--overlay-alignment);
                line-height: 1;
                overflow: visible;

                @include sf-media($from: tabp) {

                    position: absolute;
                    top: 0;
                    z-index: 99;

                    padding: var(--overlay-padding);
                    left: -0.5px;
                    width: calc(100% + 1px);
                }

                p {

                    line-height: 1.3;

                    &:first-child {
                        margin-top: 0.1rem;
                    }
                }

                a:not([role="button"]) {

                    color: var(--overlay-fg-color);
                    text-decoration: underline;
                }

                h1, h2, h3, h4, h5, h6 {

                    line-height: 1.2;

                    &:last-child {

                        margin-bottom: 0;
                    }
                }
            }

            [sf-image-wrapper] {

                --zero-width: 100%;
                --phab-width: var(--zero-width);
                --tabp-width: var(--phab-width);
                --tabl-width: var(--tabp-width);
                --note-width: var(--tabl-width);
                --desk-width: var(--note-width);
                --elas-width: var(--desk-width);
                --max-width: var(--zero-width);

                --zero-side-margins: 0;
                --phab-side-margins: 0;

                @include sf-media($from: phab) { --max-width: var(--phab-width); }
                @include sf-media($from: tabp) { --max-width: var(--tabp-width); }
                @include sf-media($from: tabl) { --max-width: var(--tabl-width); }
                @include sf-media($from: note) { --max-width: var(--note-width); }
                @include sf-media($from: desk) { --max-width: var(--desk-width); }
                @include sf-media($from: elas) { --max-width: var(--elas-width); }

                position: relative;
                left: 50%;
                transform: translateX(-50%);
                max-width: var(--max-width);
                width: var(--max-width);

                overflow: hidden;

                img {

                    width: 100%;
                    border-radius: var(--corner-radius);
                }

                & > a, & > img {

                    @include sf-media($from: tabp) {

                        &.mobile-only {

                            display: none;
                        }
                    }

                    @include sf-media($upto: tabp) {

                        &.not-mobile {

                            display: none;
                        }
                    }
                }

                .caption {

                    font-size: 0.75rem;
                    margin-top: 0.75rem;
                }
            }
        }
    }
}