.page-block-content {

    .content-block {

        &.featured-pages-block {

            // Overrides

            // Custom
            
            --current-bg-color: transparent;
            --current-fg-color: inherit;
            
            .page-cell, .page-wrapper {
                height: 100%;
            }
            
            .page-wrapper {

                padding: 1rem 0;
                
                &.current {

                    background-color: var(--current-bg-color);
                    color: var(--current-fg-color);
                    padding: 1rem;
                    border-radius: 0.25rem;
                }
            }
            
            p {
                
                font-size: 90%;
            }

            [sf-image-wrapper] {
                
                margin-bottom: 1.25rem;

                img {
                    border-radius: 0.15rem;
                    width: 100%;
                }
            }            
        }
    }
}
