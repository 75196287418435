.page-block-content {

    .content-block {

        &.checkout-block {

            .sign-in-reminder {

                padding: 1.25rem;
                background-color: var(--sf-color-light);
                border-radius: 0.25rem;
                text-align: center;
                text-wrap: pretty;
            }
        }
    }
}
