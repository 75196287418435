.page-block-content {

    .content-block {

        &.page-navigation-block {

            // Base Overrides

            --background-color: rgba(0,0,0,0.15);
            --color: #ffffff;

            // Custom

            --menu-color: var(--sf-color-primary);
            --menu-color-contrast: var(--sf-color-primary-contrast);

            --mobile-menu-background-color: var(--background-color);
            --mobile-menu-color: var(--color);
            --mobile-menu-bold-color: var(--bold-color);
            --mobile-menu-anchor-color: var(--anchor-color);
            --mobile-menu-anchor-hover-color: var(--anchor-hover-color);
            --mobile-menu-heading-color: var(--heading-color);
            --mobile-menu-accent-color: var(--accent-color);

            --scroll-nav-background-color: var(--background-color);
            --scroll-nav-color: var(--color);
            --scroll-nav-bold-color: var(--bold-color);
            --scroll-nav-anchor-color: var(--anchor-color);
            --scroll-nav-anchor-hover-color: var(--anchor-hover-color);
            --scroll-nav-heading-color: var(--heading-color);
            --scroll-nav-accent-color: var(--accent-color);

            --logo-height: 2rem;
            --menu-logo-height: var(--logo-height);
            --hamburger-size: 1.35rem;

            --mobile-menu-padding: 1.5rem 1.75rem;

            .scroll-nav-bar, .mobile-nav-bar {

                padding: 0;

                a:not([role="button"]) {

                    display: block;

                    &.logo {

                        font-size: 0;

                        img {
                            width: auto;
                            max-height: var(--logo-height);
                        }
                    }

                    &.hamburger {

                        font-size: var(--hamburger-size);
                        color: var(--color);
                        line-height: 1;
                        text-decoration: none;

                        i {

                            line-height: 1;
                        }
                    }
                }
            }

            .mobile-nav-bar {

                background-color: var(--background-color);
                color: var(--color);

                strong, b {

                    color: var(--bold-color);
                }

                h1, h2, h3, h4, h5, h6 {

                    color: var(--heading-color);
                }

                a:not([role="button"]) {
                    color: var(--anchor-color);
                }

                a:not([role="button"]):hover {
                    color: var(--anchor-hover-color);
                }
            }

            .scroll-nav-bar {

                --zero-scroll-nav-side-margins: 0;
                --phab-scroll-nav-side-margins: var(--zero-scroll-nav-side-margins);
                --tabp-scroll-nav-side-margins: var(--phab-scroll-nav-side-margins);
                --tabl-scroll-nav-side-margins: var(--tabp-scroll-nav-side-margins);
                --note-scroll-nav-side-margins: var(--tabl-scroll-nav-side-margins);
                --desk-scroll-nav-side-margins: var(--note-scroll-nav-side-margins);
                --elas-scroll-nav-side-margins: var(--desk-scroll-nav-side-margins);
                --scroll-nav-side-margins: var(--zero-scroll-nav-side-margins);

                --zero-scroll-nav-top-margin: 0;
                --phab-scroll-nav-top-margin: var(--zero-scroll-nav-top-margin);
                --tabp-scroll-nav-top-margin: var(--phab-scroll-nav-top-margin);
                --tabl-scroll-nav-top-margin: var(--tabp-scroll-nav-top-margin);
                --note-scroll-nav-top-margin: var(--tabl-scroll-nav-top-margin);
                --desk-scroll-nav-top-margin: var(--note-scroll-nav-top-margin);
                --elas-scroll-nav-top-margin: var(--desk-scroll-nav-top-margin);
                --scroll-nav-top-margin: var(--zero-scroll-nav-top-margin);

                --zero-scroll-nav-bottom-margin: 0;
                --phab-scroll-nav-bottom-margin: var(--zero-scroll-nav-bottom-margin);
                --tabp-scroll-nav-bottom-margin: var(--phab-scroll-nav-bottom-margin);
                --tabl-scroll-nav-bottom-margin: var(--tabp-scroll-nav-bottom-margin);
                --note-scroll-nav-bottom-margin: var(--tabl-scroll-nav-bottom-margin);
                --desk-scroll-nav-bottom-margin: var(--note-scroll-nav-bottom-margin);
                --elas-scroll-nav-bottom-margin: var(--desk-scroll-nav-bottom-margin);
                --scroll-nav-bottom-margin: var(--zero-scroll-nav-bottom-margin);

                --zero-scroll-nav-elastic-width: 100%;
                --phab-scroll-nav-elastic-width: var(--zero-scroll-nav-elastic-width);
                --tabp-scroll-nav-elastic-width: var(--phab-scroll-nav-elastic-width);
                --tabl-scroll-nav-elastic-width: var(--tabp-scroll-nav-elastic-width);
                --note-scroll-nav-elastic-width: var(--tabl-scroll-nav-elastic-width);
                --desk-scroll-nav-elastic-width: var(--note-scroll-nav-elastic-width);
                --elas-scroll-nav-elastic-width: var(--desk-scroll-nav-elastic-width);
                --scroll-nav-elastic-width: var(--zero-scroll-nav-elastic-width);

                @include sf-media($from: phab) {

                    --scroll-nav-top-margin: var(--phab-scroll-nav-top-margin);
                    --scroll-nav-bottom-margin: var(--phab-scroll-nav-bottom-margin);
                    --scroll-nav-elastic-width: var(--phab-scroll-nav-elastic-width);
                    --scroll-nav-side-margins: var(--phab-scroll-nav-side-margins);
                }

                @include sf-media($from: tabp) {

                    --scroll-nav-top-margin: var(--tabp-scroll-nav-top-margin);
                    --scroll-nav-bottom-margin: var(--tabp-scroll-nav-bottom-margin);
                    --scroll-nav-elastic-width: var(--tabp-scroll-nav-elastic-width);
                    --scroll-nav-side-margins: var(--tabp-scroll-nav-side-margins);
                }

                @include sf-media($from: tabl) {

                    --scroll-nav-top-margin: var(--tabl-scroll-nav-top-margin);
                    --scroll-nav-bottom-margin: var(--tabl-scroll-nav-bottom-margin);
                    --scroll-nav-elastic-width: var(--tabl-scroll-nav-elastic-width);
                    --scroll-nav-side-margins: var(--tabl-scroll-nav-side-margins);
                }

                @include sf-media($from: note) {

                    --scroll-nav-top-margin: var(--note-scroll-nav-top-margin);
                    --scroll-nav-bottom-margin: var(--note-scroll-nav-bottom-margin);
                    --scroll-nav-elastic-width: var(--note-scroll-nav-elastic-width);
                    --scroll-nav-side-margins: var(--note-scroll-nav-side-margins);
                }

                @include sf-media($from: desk) {

                    --scroll-nav-top-margin: var(--desk-scroll-nav-top-margin);
                    --scroll-nav-bottom-margin: var(--desk-scroll-nav-bottom-margin);
                    --scroll-nav-elastic-width: var(--desk-scroll-nav-elastic-width);
                    --scroll-nav-side-margins: var(--desk-scroll-nav-side-margins);
                }

                @include sf-media($from: elas) {

                    --scroll-nav-top-margin: var(--elas-scroll-nav-top-margin);
                    --scroll-nav-bottom-margin: var(--elas-scroll-nav-bottom-margin);
                    --scroll-nav-elastic-width: var(--elas-scroll-nav-elastic-width);
                    --scroll-nav-side-margins: var(--elas-scroll-nav-side-margins);
                }

                position: fixed;
                left: 0;
                top: calc(((var(--scroll-nav-top-margin) + var(--scroll-nav-bottom-margin) + var(--menu-logo-height) + var(--hamburger-size)) * -1) - 1px);
                right: 0;
                padding-top: var(--top-margin);
                padding-bottom: var(--bottom-margin);
                z-index: 9997;

                background-color: var(--scroll-nav-background-color);
                color: var(--scroll-nav-color);

                @include sf-animate(
                        $property: top,
                        $delay: 0,
                        $duration: 0.15s,
                        $kind: ease
                );

                strong, b {

                    color: var(--scroll-nav-bold-color);
                }

                h1, h2, h3, h4, h5, h6 {

                    color: var(--scroll-nav-heading-color);
                }

                a:not([role="button"]) {
                    color: var(--scroll-nav-anchor-color);
                }

                a:not([role="button"]):hover {
                    color: var(--scroll-nav-anchor-hover-color);
                }

                &.show {
                    top: -1px;
                }

                .scroll-nav-bar-inner {

                    margin: 0 auto;
                    max-width: var(--scroll-nav-elastic-width);
                    padding-top: var(--scroll-nav-top-margin);
                    padding-bottom: var(--scroll-nav-bottom-margin);
                    padding-left: var(--scroll-nav-side-margins);
                    padding-right: var(--scroll-nav-side-margins);
                }
            }

            .flyout-menu {

                display: block;
                width: 75vw;
                position: fixed;
                top: -1px;
                left: 100vw;
                height: calc(100vh + 1px);
                overflow-y: auto;
                z-index: 9999;

                background-color: var(--mobile-menu-background-color);
                color: var(--mobile-menu-color);
                padding: var(--mobile-menu-padding);

                a:not([role="button"]) {

                    display: block;
                    font-size: 1rem;
                    color: var(--mobile-menu-anchor-color);

                    &:hover {

                        color: var(--mobile-menu-anchor-hover-color);
                    }

                    & + a:not([role="button"]) {

                        margin-top: 0.5rem;
                    }

                    &.logo {

                        font-size: 0;
                        margin-bottom: 0.5rem;

                        img {
                            width: auto;
                            max-height: var(--menu-logo-height);
                        }
                    }
                }

                .menu-item-wrapper {

                    & > .submenu-wrapper {

                        margin-top: 0.5rem;

                        a:not([role="button"]) {

                            font-size: 0.8rem;
                            color: var(--mobile-menu-anchor-color);

                            &:hover {

                                color: var(--mobile-menu-anchor-hover-color);
                            }
                        }

                        .fas.fa-triangle {

                            display: none;
                        }
                    }
                }

                &.show {

                    left: 25vw;

                    @include sf-animate(
                            $property: left,
                            $delay: 0,
                            $duration: 0.15s,
                            $kind: ease
                    );

                    @include sf-media($from: tabp) {

                        width: 23rem;
                        left: calc(100vw - 23rem);
                    }
                }
            }
        }
    }
}

.page-navigation-block-menu-overlay {

    display: block;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 100vw;
    z-index: 9998;

    @include sf-animate(
            $property: background-color,
            $delay: 0,
            $duration: 0.15s,
            $kind: ease
    );

    &.show {
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
    }
}
