.page-block-content {

    .content-block {

        &.product-details-block {

            h1 {
                --h1-margin-top: 0.5rem;
                --h1-margin-bottom: 1rem;
                --h1-font-size: 2rem;
                --h1-line-height: 1.2;
            }

            .image {
                overflow: hidden;
                background-color: var(--sf-color-light);
                border-radius: 0.75rem;
                border: solid 0.15rem var(--sf-color-primary);

                img {
                    overflow: hidden;
                    width: 100%;
                    aspect-ratio: 1;
                }

                &.image-icon {
                    position: relative;
                    width: 100%;
                    aspect-ratio: 1;
                    overflow: hidden;
                    padding: 1.5rem;

                    i {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        font-size: 4rem;
                        color: var(--sf-color-white);
                    }
                }
            }

            p {
                span.price {
                    display: inline-block;
                    font-size: 1.5rem;
                    line-height: 1.5rem;

                    span.cents {
                        font-size: 1rem;
                        line-height: 1.5rem;
                        vertical-align: text-top;
                    }
                }
            }

            div {
                span.out-of-stock {
                    color: var(--sf-color-danger);
                }
            }

            .sign-in-reminder {
                padding: 1.25rem;
                background-color: var(--sf-color-light);
                border-radius: 0.25rem;
                text-align: center;
                text-wrap: pretty;
            }

            a[role="button"] {
                width: 100%;

                &.cart {
                    --button-color: var(--sf-color-tertiary);
                }
            }
            
            .nutrition-facts-image {
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                
                width: 5rem;
                height: 5rem;
                margin-bottom: var(--paragraph-spacing);
                
                overflow: hidden;
                cursor: pointer;
                border-radius: 0.75rem;
                border: solid 0.15rem var(--sf-color-primary);
                
                &:hover {
                    border-color: var(--sf-color-secondary);
                }
            }
        }
    }
}

.embiggen_overlay {
    cursor: zoom-out;
}