.page-block-content {

    .content-block {

        &.question-and-answer-lists-block {

            --tab-border-color: transparent;
            --tab-bg-color: transparent;
            --tab-fg-color: inherit;
            --bubble-bg-color: var(--sf-color-white);
            --bubble-fg-color: var(--sf-color-white-contrast);

            // Base Overrides

            [sf-scrollable] {
                
                margin-left: 0;
                margin-right: 0;
            }
            
            .cb-overlay {

                padding-bottom: 0;
                
                .cb-elastic-wrapper {

                    max-width: 100vw;

                    @include sf-media($from: phab) {

                        max-width: 100vw;
                    }

                    @include sf-media($from: tabp) {

                        max-width: 100vw;
                    }

                    @include sf-media($from: tabl) {

                        max-width: 100vw;
                    }

                    @include sf-media($from: note) {

                        max-width: 100vw;
                    }

                    @include sf-media($from: desk) {

                        max-width: 100vw;
                    }

                    @include sf-media($from: elas) {

                        max-width: 100vw;
                    }
                    
                    .cb-margin-wrapper {

                        padding: 0 0;

                        @include sf-media($from: phab) {

                            padding: 0 0;
                        }

                        @include sf-media($from: tabp) {

                            padding: 0 0;
                        }

                        @include sf-media($from: tabl) {

                            padding: 0 0;
                        }

                        @include sf-media($from: note) {

                            padding: 0 0;
                        }

                        @include sf-media($from: desk) {

                            padding: 0 0;
                        }

                        @include sf-media($from: elas) {

                            padding: 0 0;
                        }
                    }
                }
            }
            
            // Custom
            
            .title-excerpt-bar {

                margin-bottom: 2rem;

                @include sf-media($from: tabp) {

                    margin-bottom: 3rem;
                }
            }
            
            .tab-grid, .qa-item-grid {

                margin: 0 auto;
                max-width: var(--elastic-width);

                @include sf-media($from: phab) {

                    --elastic-width: var(--phab-elastic-width);
                }

                @include sf-media($from: tabp) {

                    --elastic-width: var(--tabp-elastic-width);
                }

                @include sf-media($from: tabl) {

                    --elastic-width: var(--tabl-elastic-width);
                }

                @include sf-media($from: note) {

                    --elastic-width: var(--note-elastic-width);
                }

                @include sf-media($from: desk) {

                    --elastic-width: var(--desk-elastic-width);
                }

                @include sf-media($from: elas) {

                    --elastic-width: var(--elas-elastic-width);
                }
            }
            
            .tab-grid {
                
                .tab {
                 
                    display: block;
                    position: relative;
                    cursor: pointer;
                    height: 100%;
                    padding: 1rem 2rem;
                    border-left: solid 0.125rem;
                    border-top: solid 0.125rem;
                    border-right: solid 0.125rem;
                    border-radius: 0.5rem 0.5rem 0 0;
                    border-left-color: var(--tab-border-color);
                    border-top-color: var(--tab-border-color);
                    border-right-color: var(--tab-border-color);
                    text-align: center;
                    font-size: 1.25rem;
                    font-family: var(--sf-font-family-heading);
                    text-decoration: none;
                    
                    &.current {

                        background-color: var(--tab-bg-color);
                        color: var(--tab-fg-color);
                        
                        border-left-color: transparent;
                        border-top-color: transparent;
                        border-right-color: transparent;
                    }
                    
                    & > * {
                        position: relative;
                        top: 55%;
                        transform: translateY(-50%);
                    }
                }                                
            }
            
            .qa-items {

                display: none;
                background-color: var(--tab-bg-color);
                color: var(--tab-fg-color);
                padding: 1.25rem;
                border-bottom-left-radius: 0.5rem;
                border-bottom-right-radius: 0.5rem;

                @include sf-media($from: tabp) {

                    padding: 2rem;
                }
                
                &.current {
                    
                    display: block;
                }
                
                .qa-item-grid {

                    .qa-item {

                        padding-top: 1.5rem;
                        border-top: solid 1px rgba(0,0,0,0.15);                        

                        &.first {

                            padding-top: 0;
                            border-top: none;
                        }
                        
                        h6 {

                            cursor: pointer;
                            margin-top: 0;
                            margin-bottom: 0;
                        }
                        
                        .disclosure {

                            cursor: pointer;
                            color: var(--sf-color-primary);

                            .hidden {

                                display: none !important;
                            }
                        }               
                        
                        .fa-plus {
                        
                            display: inline-block;
                        }
                        
                        .fa-minus {

                            display: none;
                        }
                        
                        .answer {
                            
                            display: grid;
                            grid-template-rows: 0fr;
                            transition: grid-template-rows 250ms;
                            
                            .answer-wrapper {

                                overflow: hidden;

                                .fa-triangle {

                                    position: relative;
                                    left: 2rem;
                                    font-size: 2rem;
                                    color: var(--bubble-bg-color);
                                    bottom: -1rem;
                                }

                                .answer-inner {

                                    padding: 1.25rem 1.5rem;
                                    background-color: var(--bubble-bg-color);
                                    color: var(--bubble-fg-color);
                                    border-radius: 0.5rem;
                                }
                            }
                        }
                        
                        &.open {

                            .fa-plus {

                                display: none;
                            }

                            .fa-minus {

                                display: inline-block;
                            }
                            
                            .answer {

                                grid-template-rows: 1fr;
                            }
                        }
                    }
                }
            }
        }
    }
}
