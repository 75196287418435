.page-block-content {

    .content-block {

        &.you-tube-block {

            // Base Overrides

            // Custom
            
            --corner-radius: 0.25rem;

            [sf-video-wrapper] [sf-video], [sf-video-wrapper] video {
                
                border-radius: var(--corner-radius);                
            }            
        }
    }
}
