@import "manifest";

// $HALIDE_PARTIALS:BEGIN
@import "custom/content-blocks/__content-blocks.scss";
@import "custom/content-blocks/__video-player.scss";
@import "custom/content-blocks/Partials/_AccountInfo.cshtml.scss";
@import "custom/content-blocks/Partials/_Confirmation.cshtml.scss";
@import "custom/content-blocks/Partials/_CustomerInformation.cshtml.scss";
@import "custom/content-blocks/Partials/_OrdersList.cshtml.scss";
@import "custom/content-blocks/Partials/_Payment.cshtml.scss";
@import "custom/content-blocks/Partials/_Security.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/AccordionItemBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/AccountPortalBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/ActiveImageBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/BlockTemplateBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/ButtonBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/CheckoutBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/CodeBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/CookieConsentBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/ExpandableCtaBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/FeaturedNewsBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/FeaturedPagesBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/FeaturePanelBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/FileAttachmentBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/ForgotPasswordRequestBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/ForgotPasswordResetBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/FormattedTextBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/FormBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/HeadingBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/HorizontalLineBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/HyperlinkBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/ImageHeadingBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/ImageHeroBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/ImageThumbnailsBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/InsuranceFormBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/LatestPageBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/MegaMenuHeaderBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/MultiColumnBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/NewZoiaPageHeroBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/PageAnchorBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/PageCollectionBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/PageCollectionGridBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/PageCollectionHeaderBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/PageNavigationBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/PaymentFormBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/PentecHealthFooterBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/PentecHealthPageHeroBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/PortalSigninBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/PortalSigninBlockZoia.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/PortalSignupBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/PortalSignupBlockZoia.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/ProductDetailsBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/ProductsListBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/ProductsListHeaderBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/PropleteStrongFooterBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/PropleteStrongNavigationBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/PropleteStrongQuestionnaireBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/PropleteStrongWindowTabBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/QuestionAndAnswerListsBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/QuoteCarouselBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/RoundedPanelBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/ShoppingCartBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/SimpleImageBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/SlidingPageNavigationBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/SocialIconsBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/SpacerBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/SquareImageBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/StackBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/TextRibbonBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/ThemeSwitchBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/ThreeColumnBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/TwoColumnBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/TwoColumnTextBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/UmbracoFormsBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/VerticalFeaturePanelBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/WebinarsBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/YouTubeBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/ZoiaForgotPasswordRequestBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/ZoiaForgotPasswordResetBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/ZoiaPageHeroBlock.cshtml.scss";
@import "custom/content-blocks/Partials/BlockList/Components/zzzPentecGlobalOverrides.scss";
// $HALIDE_PARTIALS:END

html, body {

    background-color: var(--background-color);
    color: var(--color);
    overflow: visible;
}

main {
    
    max-width: 100vw;
    overflow-x: hidden;
}

a:not([role="button"]) {
    transition-property: color;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    animation-delay: 0s;
}
