.page-block-content {

    .content-block {

        &.account-portal-block {

            .account-security-partial {

                [sf-form] {

                    [sf-validation-message] {

                        color: var(--sf-color-danger);
                    }
                }
            }
        }
    }
}
