.page-block-content {

    .content-block {

        &.proplete-strong-window-tab-block {

            --background-color: var(--sf-color-white);
            --intro-text-color: var(--sf-color-primary);
            --icon-color: var(--sf-color-primary);
            --link-text-color: var(--sf-color-primary);
            --close-text-color: var(--sf-color-black);
            --max-width: 14rem;
            
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
            background-color: var(--background-color);
            box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.15);
            padding: 1.25rem;
            max-width: var(--max-width);
            position: fixed;
            right: calc((var(--max-width) * -1) + 0.5rem);
            top: 50%;
            transform: translateY(-50%);
            z-index: 10999;
            text-align: center;
            cursor: pointer;

            transition-property: none;
            transition-duration: 0.15s;
            transition-timing-function: ease-out;
            animation-delay: 0s;
            
            @include sf-media($from: tabp) {

                right: calc((var(--max-width) * -1) + 1rem);
            }
            
            &.visible {
                
                right: 0;
            }

            .tab-intro-text, .tab-link-text {
                
                &, & > a {

                    font-size: 1rem;
                    font-weight: 600;
                    line-height: 1.4;
                }
            }
            
            .tab-intro-text {
                
                color: var(--intro-text-color);
                margin-bottom: 1rem;
            }

            .tab-icon {

                margin-bottom: 1rem;

                & > a, & > a:hover {
                    color: var(--icon-color);
                    font-size: 3rem;
                    line-height: 1;
                }
            }

            .tab-link-text {

                margin-bottom: 1rem;

                & > a, & > a:hover {
                    color: var(--link-text-color);
                }
            }

            .tab-close-link > a {

                --anchor-font-weight: 500;
                
                font-size: 0.85rem;
                margin-bottom: 0;
                
                &, &:hover {
                    color: var(--close-text-color);
                }
            }
        }
    }
}
