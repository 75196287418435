.page-block-content {

    .content-block {

        &.social-icons-block {
            
            --zero-size: 2rem;
            --phab-size: var(--zero-size);
            --tabp-size: var(--phab-size);
            --tabl-size: var(--tabp-size);
            --note-size: var(--tabl-size);
            --desk-size: var(--note-size);
            --elas-size: var(--desk-size);
            
            --zero-grid-cols: repeat(auto-fit, var(--zero-size));
            --phab-grid-cols: repeat(auto-fit, var(--phab-size));
            --tabp-grid-cols: repeat(auto-fit, var(--tabp-size));
            --tabl-grid-cols: repeat(auto-fit, var(--tabl-size));
            --note-grid-cols: repeat(auto-fit, var(--note-size));
            --desk-grid-cols: repeat(auto-fit, var(--desk-size));
            --elas-grid-cols: repeat(auto-fit, var(--elas-size));

            --zero-grid-child-gap: 2rem;
            --phab-grid-child-gap: var(--zero-grid-child-gap);
            --tabp-grid-child-gap: var(--phab-grid-child-gap);
            --tabl-grid-child-gap: var(--tabp-grid-child-gap);
            --note-grid-child-gap: var(--tabl-grid-child-gap);
            --desk-grid-child-gap: var(--note-grid-child-gap);
            --elas-grid-child-gap: var(--desk-grid-child-gap);

            --zero-grid-col-gap: 2rem;
            --phab-grid-col-gap: var(--zero-grid-col-gap);
            --tabp-grid-col-gap: var(--phab-grid-col-gap);
            --tabl-grid-col-gap: var(--tabp-grid-col-gap);
            --note-grid-col-gap: var(--tabl-grid-col-gap);
            --desk-grid-col-gap: var(--note-grid-col-gap);
            --elas-grid-col-gap: var(--desk-grid-col-gap);

            --zero-grid-row-gap: 2rem;
            --phab-grid-row-gap: var(--zero-grid-row-gap);
            --tabp-grid-row-gap: var(--phab-grid-row-gap);
            --tabl-grid-row-gap: var(--tabp-grid-row-gap);
            --note-grid-row-gap: var(--tabl-grid-row-gap);
            --desk-grid-row-gap: var(--note-grid-row-gap);
            --elas-grid-row-gap: var(--desk-grid-row-gap);

            --zero-grid-align: initial;
            --phab-grid-align: var(--zero-grid-align);
            --tabp-grid-align: var(--phab-grid-align);
            --tabl-grid-align: var(--tabp-grid-align);
            --note-grid-align: var(--tabl-grid-align);
            --desk-grid-align: var(--note-grid-align);
            --elas-grid-align: var(--desk-grid-align);

            --zero-grid-valign: start;
            --phab-grid-valign: var(--zero-grid-valign);
            --tabp-grid-valign: var(--phab-grid-valign);
            --tabl-grid-valign: var(--tabp-grid-valign);
            --note-grid-valign: var(--tabl-grid-valign);
            --desk-grid-valign: var(--note-grid-valign);
            --elas-grid-valign: var(--desk-grid-valign);

            --zero-grid-cell-side-padding: 0;
            --phab-grid-cell-side-padding: var(--zero-grid-cell-side-padding);
            --tabp-grid-cell-side-padding: var(--phab-grid-cell-side-padding);
            --tabl-grid-cell-side-padding: var(--tabp-grid-cell-side-padding);
            --note-grid-cell-side-padding: var(--tabl-grid-cell-side-padding);
            --desk-grid-cell-side-padding: var(--note-grid-cell-side-padding);
            --elas-grid-cell-side-padding: var(--desk-grid-cell-side-padding);

            --zero-grid-cell-vertical-padding: 0;
            --phab-grid-cell-vertical-padding: var(--zero-grid-cell-vertical-padding);
            --tabp-grid-cell-vertical-padding: var(--phab-grid-cell-vertical-padding);
            --tabl-grid-cell-vertical-padding: var(--tabp-grid-cell-vertical-padding);
            --note-grid-cell-vertical-padding: var(--tabl-grid-cell-vertical-padding);
            --desk-grid-cell-vertical-padding: var(--note-grid-cell-vertical-padding);
            --elas-grid-cell-vertical-padding: var(--desk-grid-cell-vertical-padding);

            --zero-grid-cell-height: auto;
            --phab-grid-cell-height: var(--zero-grid-cell-height);
            --tabp-grid-cell-height: var(--phab-grid-cell-height);
            --tabl-grid-cell-height: var(--tabp-grid-cell-height);
            --note-grid-cell-height: var(--tabl-grid-cell-height);
            --desk-grid-cell-height: var(--note-grid-cell-height);
            --elas-grid-cell-height: var(--desk-grid-cell-height);

            & > .cb-overlay > .cb-elastic-wrapper > .cb-margin-wrapper > [sf-grid] {

                --sf-cols: var(--zero-grid-cols);
                --sf-phab-cols: var(--phab-grid-cols);
                --sf-tabp-cols: var(--tabp-grid-cols);
                --sf-tabl-cols: var(--tabl-grid-cols);
                --sf-note-cols: var(--note-grid-cols);
                --sf-desk-cols: var(--desk-grid-cols);
                --sf-elas-cols: var(--elas-grid-cols);
                --sf-col-gap: var(--zero-grid-col-gap);
                --sf-phab-col-gap: var(--phab-grid-col-gap);
                --sf-tabp-col-gap: var(--tabp-grid-col-gap);
                --sf-tabl-col-gap: var(--tabl-grid-col-gap);
                --sf-note-col-gap: var(--note-grid-col-gap);
                --sf-desk-col-gap: var(--desk-grid-col-gap);
                --sf-elas-col-gap: var(--elas-grid-col-gap);
                --sf-row-gap: var(--zero-grid-row-gap);
                --sf-phab-row-gap: var(--phab-grid-row-gap);
                --sf-tabp-row-gap: var(--tabp-grid-row-gap);
                --sf-tabl-row-gap: var(--tabl-grid-row-gap);
                --sf-note-row-gap: var(--note-grid-row-gap);
                --sf-desk-row-gap: var(--desk-grid-row-gap);
                --sf-elas-row-gap: var(--elas-grid-row-gap);
                --sf-align-items: var(--zero-grid-align);
                --sf-phab-align-items: var(--phab-grid-align);
                --sf-tabp-align-items: var(--tabp-grid-align);
                --sf-tabl-align-items: var(--tabl-grid-align);
                --sf-note-align-items: var(--note-grid-align);
                --sf-desk-align-items: var(--desk-grid-align);
                --sf-elas-align-items: var(--elas-grid-align);
                --sf-vertical-align-items: var(--zero-grid-valign);
                --sf-phab-vertical-align-items: var(--phab-grid-valign);
                --sf-tabp-vertical-align-items: var(--tabp-grid-valign);
                --sf-tabl-vertical-align-items: var(--tabl-grid-valign);
                --sf-note-vertical-align-items: var(--note-grid-valign);
                --sf-desk-vertical-align-items: var(--desk-grid-valign);
                --sf-elas-vertical-align-items: var(--elas-grid-valign);
            }

            & > .cb-overlay > .cb-elastic-wrapper > .cb-margin-wrapper > [sf-grid] > [sf-grid-cell] {

                & > [sf-grid], & > [role="presentation"] > [sf-grid] {

                    --sf-row-gap: var(--zero-grid-child-gap);
                    --sf-phab-row-gap: var(--phab-grid-child-gap);
                    --sf-tabp-row-gap: var(--tabp-grid-child-gap);
                    --sf-tabl-row-gap: var(--tabl-grid-child-gap);
                    --sf-note-row-gap: var(--note-grid-child-gap);
                    --sf-desk-row-gap: var(--desk-grid-child-gap);
                    --sf-elas-row-gap: var(--elas-grid-child-gap);
                }

                &, & > [role="presentation"] {

                    &, & > .content-block {

                        height: var(--zero-grid-cell-height);

                        @include sf-media($from: phab) {
                            height: var(--phab-grid-cell-height);
                        }

                        @include sf-media($from: tabp) {
                            height: var(--tabp-grid-cell-height);
                        }

                        @include sf-media($from: tabl) {
                            height: var(--tabl-grid-cell-height);
                        }

                        @include sf-media($from: note) {
                            height: var(--note-grid-cell-height);
                        }

                        @include sf-media($from: desk) {
                            height: var(--desk-grid-cell-height);
                        }

                        @include sf-media($from: elas) {
                            height: var(--elas-grid-cell-height);
                        }
                    }
                }
            }

            & > .cb-overlay > .cb-elastic-wrapper > .cb-margin-wrapper > [sf-grid] > [sf-grid-cell] > [role="presentation"] {

                padding: var(--zero-grid-cell-vertical-padding) var(--zero-grid-cell-side-padding);

                @include sf-media($from: phab) {
                    padding: var(--phab-grid-cell-vertical-padding) var(--phab-grid-cell-side-padding);
                }

                @include sf-media($from: tabp) {
                    padding: var(--tabp-grid-cell-vertical-padding) var(--tabp-grid-cell-side-padding);
                }

                @include sf-media($from: tabl) {
                    padding: var(--tabl-grid-cell-vertical-padding) var(--tabl-grid-cell-side-padding);
                }

                @include sf-media($from: note) {
                    padding: var(--note-grid-cell-vertical-padding) var(--note-grid-cell-side-padding);
                }

                @include sf-media($from: desk) {
                    padding: var(--desk-grid-cell-vertical-padding) var(--desk-grid-cell-side-padding);
                }

                @include sf-media($from: elas) {
                    padding: var(--elas-grid-cell-vertical-padding) var(--elas-grid-cell-side-padding);
                }
            }
            
            --icon-size: var(--zero-size);

            @include sf-media($from: phab) {

                --icon-size: var(--phab-size);
            }

            @include sf-media($from: tabp) {

                --icon-size: var(--tabp-size);
            }

            @include sf-media($from: tabl) {

                --icon-size: var(--tabl-size);
            }

            @include sf-media($from: note) {

                --icon-size: var(--note-size);
            }

            @include sf-media($from: desk) {

                --icon-size: var(--desk-size);
            }

            @include sf-media($from: elas) {

                --icon-size: var(--elas-size);
            }

            p {
                
                text-align: center;
            }
            
            .social-icons-grid {
             
                display: flex;
                flex-wrap: wrap;
                position: relative;
                justify-content: center;
                width: max-content;
                margin: auto;
                
                img {
                    max-width: var(--icon-size);
                    max-height: var(--icon-size);
                }
            }
        }
    }
}
