.orders-list-partial {

    .order-row {

        margin-top: 1.5rem;
        border-top: solid 1px rgba(0,0,0,0.1);
        padding-top: 1.5rem;

        @include sf-media($from: tabp) {
            margin-top: 0.5rem;
            border-top: solid 1px rgba(0,0,0,0.1);
            padding-top: 0.5rem;
        }
    }
    
    .order.panel {
        border-bottom-color: grey;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        margin-bottom: 1rem;
    }

    .panel {

        overflow: hidden;

        .align-right {
            text-align: right;
        }

        .align-center {
            text-align: center;
        }
        
        &.subtotal {
            padding-top: 2rem;
            border-top: none;
        }

        img {
            overflow: hidden;
            width: 100%;
            aspect-ratio: 1;
        }

        .image-icon {
            position: relative;
            width: 100%;
            aspect-ratio: 1;
            overflow: hidden;
            padding: 1.5rem;
            background-color: var(--sf-color-light);

            i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 4rem;
                color: var(--sf-color-white);
            }
        }

        h5 {
            --line-height: 1.3;
            --h5-margin-top: 0;
            --h5-margin-top-first: 0;
            --h5-margin-bottom: 0;
        }

        h6 {
            --line-height: 1.3;
            --h6-margin-top: 1rem;
            --h6-margin-bottom: 0.25rem;
        }

        p {

            @include sf-media($from: tabp) {

                @include sf-line-clamp($lines: 3);
            }

            &.total {
                text-align: right;
                font-size: 1.5rem;

                .total-label {
                    display: inline-block;
                    margin-right: 0.75rem;
                }

                span.price {
                    span.dollars, span.cents {
                        font-weight: 600;
                    }
                }
            }

            span.price {
                display: inline-block;
                font-size: 1.5rem;
                line-height: 1.5rem;

                span.cents {
                    font-size: 1rem;
                    line-height: 1.5rem;
                    vertical-align: text-top;
                }
            }

            &.order-info {
                font-weight: bold;
            }
        }

        a:not([role="button"]) {
            display: block;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }

            &.image {
                overflow: hidden;
                border-radius: 0.75rem;
                border: solid 0.15rem rgba(0,0,0,0.07);

                &:hover {
                    border-color: var(--sf-color-anchor);
                }
            }
        }
    }

    .pagination {

        text-align: center;
        padding-top: 2rem;

        a:not([role="button"]) {

            position: relative;
            display: inline-block;
            margin: 0.25rem;
            text-decoration: none;
            width: 2.5rem;
            height: 2.5rem;
            background-color: var(--sf-color-primary);
            color: var(--sf-color-primary-contrast);
            border-radius: 0.15rem;
            line-height: 0;
            font-size: 0;

            &:hover {
                background-color: var(--sf-color-secondary);
                color: var(--sf-color-secondary-contrast);
            }

            &.current {
                opacity: 0.25;
            }

            & > span {
                position: absolute;
                display: inline-block;
                top: 55%;
                left: 50%;
                transform: translate(-50%, -50%);
                white-space: nowrap;
                line-height: 1;
                font-size: 1rem;
            }
        }
    }

    .search-bar {
        position: relative;
        overflow: hidden;
        
        .search-inner {
            padding: 1.25rem 0;
            padding-bottom: 2.5rem;
            
            @include sf-media($from: tabp) {
                //display: flex;
                //justify-content: end;
            }
            
            [sf-validation-message] {
                font-weight: normal;
                font-size: 0.7rem;
                color: gray;
            }
        }
    }
}
