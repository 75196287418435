.page-block-content {

    .content-block {

        &.pentec-health-footer-block {

            font-size: 0.9rem;
            
            .contact-icon {
                font-size: 1.5rem;
                vertical-align: middle;
                margin-right: 0.75rem;
            }
        }
    }
}

.footer-cap {

    --cap-bg-color: var(--sf-color-secondary);
    --cap-anchor-color: var(--sf-color-secondary-contrast);
    --cap-anchor-hover-color: var(--sf-color-secondary-contrast);

    --zero-elastic-width: 100%;
    --phab-elastic-width: var(--zero-elastic-width);
    --tabp-elastic-width: var(--phab-elastic-width);
    --tabl-elastic-width: var(--tabp-elastic-width);
    --note-elastic-width: var(--tabl-elastic-width);
    --desk-elastic-width: var(--note-elastic-width);
    --elas-elastic-width: var(--desk-elastic-width);
    --elastic-width: var(--zero-elastic-width);

    --zero-side-margins: 0;
    --phab-side-margins: var(--zero-side-margins);
    --tabp-side-margins: var(--phab-side-margins);
    --tabl-side-margins: var(--tabp-side-margins);
    --note-side-margins: var(--tabl-side-margins);
    --desk-side-margins: var(--note-side-margins);
    --elas-side-margins: var(--desk-side-margins);
    --side-margins: var(--zero-side-margins);

    @include sf-media($from: phab) {
        --elastic-width: var(--phab-elastic-width);
        --side-margins: var(--phab-side-margins);
    }

    @include sf-media($from: tabp) {
        --elastic-width: var(--tabp-elastic-width);
        --side-margins: var(--tabp-side-margins);
    }

    @include sf-media($from: tabl) {
        --elastic-width: var(--tabl-elastic-width);
        --side-margins: var(--tabl-side-margins);
    }

    @include sf-media($from: note) {
        --elastic-width: var(--note-elastic-width);
        --side-margins: var(--note-side-margins);
    }

    @include sf-media($from: desk) {
        --elastic-width: var(--desk-elastic-width);
        --side-margins: var(--desk-side-margins);
    }

    @include sf-media($from: elas) {
        --elastic-width: var(--elas-elastic-width);
        --side-margins: var(--elas-side-margins);
    }
    
    background-color: var(--cap-bg-color);
    color: var(--cap-anchor-color);

    .footer-cap-inner {

        position: relative;
        margin: 0 auto;
        max-width: var(--elastic-width);
        padding: 2.5rem var(--side-margins);
        
        a:not([role="button"]) {
            color: var(--cap-anchor-color);
            &:hover {
                color: var(--cap-anchor-hover-color);
            }
        }

        @include sf-media($from: tabp) {

            padding: 1.5rem var(--side-margins);
        }
        
        .copyright {

            text-align: center;
            line-height: 1.3;
            
            @include sf-media($from: tabp) {

                text-align: left;
            }
        }
    }
}