.page-block-content {

    .content-block {

        &.products-list-block {

            // Base Overrides

            // Custom

            --background-color: var(--sf-color-light);
            --accent-color: var(--sf-color-white);
            --color: var(--sf-color-black);

            background-color: var(--background-color);
            color: var(--color);

            .not-found {

                text-align: center;

                h3 {
                    margin-bottom: 0.5rem;
                }
            }

            h2 {
                margin-bottom: 2rem;
            }
            
            .panel {

                background-color: var(--accent-color);
                color: var(--color);
                overflow: hidden;

                img {

                    overflow: hidden;
                    width: 100%;
                    aspect-ratio: 1;
                }

                .image-icon {

                    position: relative;
                    width: 100%;
                    aspect-ratio: 1;
                    overflow: hidden;
                    padding: 1.5rem;
                    background-color: var(--sf-color-light);

                    i {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        font-size: 4rem;
                        color: var(--sf-color-white);
                    }
                }

                h6 {
                    --line-height: 1.3;
                    --h6-margin-top: 1rem;
                    --h6-margin-bottom: 0.25rem;
                }
                
                p {

                    @include sf-media($from: tabp) {

                        @include sf-line-clamp($lines: 3);
                    }

                    &:not(:last-child) {
                        margin-bottom: 1rem;
                    }
                    
                    span.dollars {
                        font-size: 1.5rem;
                    }
                    
                    span.cents {
                        font-size: 1rem;
                        vertical-align: super;
                    }
                }

                a:not([role="button"]) {

                    display: block;
                    text-decoration: none;

                    &:hover {

                        text-decoration: underline;
                    }
                    
                    &.image {

                        overflow: hidden;
                        border-radius: 0.75rem;
                        border: solid 0.15rem rgba(0,0,0,0.07);

                        &:hover {

                            border-color: var(--sf-color-anchor);
                        }
                    }
                }
                
                &.product-hidden {
                    a:not([role="button"]) {
                        color: #b1b1b1;
                    }

                    p {
                        color: #b1b1b1;
                    
                    }
                    
                    img {
                        filter: grayscale(100%);
                    }
                }
            }

            .pagination {

                margin-top: 3rem;
                text-align: center;

                a:not([role="button"]) {

                    position: relative;
                    display: inline-block;
                    margin: 0.25rem;
                    text-decoration: none;
                    width: 2.5rem;
                    height: 2.5rem;
                    background-color: var(--sf-color-primary);
                    color: var(--sf-color-primary-contrast);
                    border-radius: 0.15rem;
                    line-height: 0;
                    font-size: 0;

                    &:hover {
                        background-color: var(--sf-color-secondary);
                        color: var(--sf-color-secondary-contrast);
                    }

                    &.current {

                        opacity: 0.25;
                    }

                    & > span {

                        position: absolute;
                        display: inline-block;
                        top: 55%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        white-space: nowrap;
                        line-height: 1;
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}