.page-block-content {

    .content-block {

        &.page-anchor-block {

            // Base Overrides

            --zero-top-margin: 0 !important;
            --phab-top-margin: 0 !important;
            --tabp-top-margin: 0 !important;
            --tabl-top-margin: 0 !important;
            --note-top-margin: 0 !important;
            --desk-top-margin: 0 !important;
            --elas-top-margin: 0 !important;

            --zero-bottom-margin: 0 !important;
            --phab-bottom-margin: 0 !important;
            --tabp-bottom-margin: 0 !important;
            --tabl-bottom-margin: 0 !important;
            --note-bottom-margin: 0 !important;
            --desk-bottom-margin: 0 !important;
            --elas-bottom-margin: 0 !important;

            // Custom

            

        }
    }
}
