.page-block-content {

    .content-block {

        &.shopping-cart-block {
            // Base Overrides
            // Custom

            --background-color: var(--sf-color-light);
            --accent-color: var(--sf-color-white);
            --color: var(--sf-color-black);
            background-color: var(--background-color);
            color: var(--color);

            [sf-validation-message] {
                color: var(--sf-color-danger);
            }

            h2 {
                margin-bottom: 2rem;
            }

            .panel {
                background-color: var(--accent-color);
                color: var(--color);
                overflow: hidden;

                & + .panel {
                    padding-top: 2rem;
                    border-top: solid 1px var(--sf-color-medium);
                }

                &.subtotal {
                    border-top: none;
                }

                img {
                    overflow: hidden;
                    width: 100%;
                    aspect-ratio: 1;
                }

                .image-icon {
                    position: relative;
                    width: 100%;
                    aspect-ratio: 1;
                    overflow: hidden;
                    padding: 1.5rem;
                    background-color: var(--sf-color-light);

                    i {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        font-size: 4rem;
                        color: var(--sf-color-white);
                    }
                }

                h6 {
                    --line-height: 1.3;
                    --h6-margin-top: 1rem;
                    --h6-margin-bottom: 0.25rem;
                }

                p {

                    @include sf-media($from: tabp) {

                        @include sf-line-clamp($lines: 3);
                    }

                    &:not(:last-child) {
                        margin-bottom: 1rem;
                    }

                    &.total {
                        text-align: right;
                        font-size: 1.25rem;

                        .total-label {
                            display: inline-block;
                        }

                        span.price {
                            span.dollars, span.cents {
                                font-weight: 400;
                            }
                        }
                    }

                    span.price {
                        display: inline-block;
                        font-size: 1.5rem;
                        line-height: 1.5rem;

                        span.cents {
                            font-size: 1rem;
                            line-height: 1.5rem;
                            vertical-align: text-top;
                        }
                    }
                }

                a:not([role="button"]) {
                    display: block;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }

                    &.image {
                        overflow: hidden;
                        border-radius: 0.75rem;
                        border: solid 0.15rem rgba(0,0,0,0.07);

                        &:hover {
                            border-color: var(--sf-color-anchor);
                        }
                    }

                    &.danger {
                        span {
                            font-size: 1rem;
                            color: var(--sf-color-danger);
                        }
                    }
                }
            }

            a[role="button"] {
                width: 100%;

                &.checkout {
                    --button-color: var(--sf-color-tertiary);
                    --color: var(--sf-color-tertiary-contrast);
                }

                &.danger {
                    --button-color: var(--sf-color-danger);
                    --color: var(--sf-color-danger-contrast);
                }
            }
        }
    }
}
