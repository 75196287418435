.page-block-content {

    .content-block {

        &.image-thumbnails-block {

            // Base Overrides

            // Custom

            --zero-thumbnail-height: auto;
            --tabp-thumbnail-height: var(--zero-thumbnail-height);
            --tabl-thumbnail-height: var(--tabp-thumbnail-height);
            --thumbnail-height: var(--zero-thumbnail-height);
            
            --zero-thumbnail-gutter: 1rem;
            --tabp-thumbnail-gutter: var(--zero-thumbnail-gutter);
            --tabl-thumbnail-gutter: var(--tabp-thumbnail-gutter);
            --thumbnail-gutter: var(--zero-thumbnail-gutter);

            --zero-thumbnail-corner-radius: 0.25rem;
            --tabp-thumbnail-corner-radius: var(--zero-thumbnail-corner-radius);
            --tabl-thumbnail-corner-radius: var(--tabp-thumbnail-corner-radius);
            --thumbnail-corner-radius: var(--zero-thumbnail-corner-radius);
            --fluidbox-overlay-color: rgba(255, 255, 255, 0.9);

            margin-left: calc(var(--thumbnail-gutter) * -1);
            margin-bottom: calc(var(--thumbnail-gutter) * -1);
            font-size: 0;

            div[sf-image-wrapper].thumbnail {

                display: inline-block;
                margin-left: var(--thumbnail-gutter);
                margin-bottom: var(--thumbnail-gutter);
                
                img {
                    cursor: zoom-in;
                    display: inline-block;
                    width: auto;
                    max-width: 100%;
                    height: var(--thumbnail-height);
                    overflow: hidden;
                    border-radius: var(--thumbnail-corner-radius);
                }
            }

            @include sf-media($from: tabp) {
                
                --thumbnail-height: var(--tabp-thumbnail-height);
                --thumbnail-gutter: var(--tabp-thumbnail-gutter);
                --thumbnail-corner-radius: var(--tabp-thumbnail-corner-radius);
            }

            @include sf-media($from: tabl) {

                --thumbnail-height: var(--tabl-thumbnail-height);
                --thumbnail-gutter: var(--tabl-thumbnail-gutter);
                --thumbnail-corner-radius: var(--tabl-thumbnail-corner-radius);
            }

            .embiggen_overlay {

                background-color: var(--fluidbox-overlay-color) !important;
                cursor: zoom-out;
                
                .embiggen_image_wrapper {
                    
                    width: 100% !important;
                    height: 100% !important;

                    @include sf-media($from: tabp) {

                        width: calc(100% - 12rem) !important;
                        height: calc(100% - 12rem) !important;
                    }
                }
            }
        }
    }
}
