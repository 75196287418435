.payment-partial {

    .checkout-item-grid {

        @include sf-media($from: tabp) {
            max-width: 50%;
        }
    }

    [sf-validation-message] {
            color: var(--sf-color-danger);
        }
}
