.page-block-content {

    .content-block {

        &.proplete-strong-navigation-block {

            // Base Overrides

            // Custom

            --mobile-menu-background-color: var(--background--color);
            --mobile-menu-color: var(--color);
            --mobile-menu-bold-color: var(--bold-color);
            --mobile-menu-anchor-color: var(--anchor-color);
            --mobile-menu-anchor-hover-color: var(--anchor-hover-color);
            --mobile-menu-heading-color: var(--heading-color);
            --mobile-menu-accent-color: var(--accent-color);

            --message-bar-background-color: var(--mobile-menu-background--color);
            --message-bar-color: var(--mobile-menu-color);
            --message-bar-bold-color: var(--mobile-menu-bold-color);
            --message-bar-anchor-color: var(--mobile-menu-anchor-color);
            --message-bar-anchor-hover-color: var(--mobile-menu-anchor-hover-color);
            --message-bar-heading-color: var(--mobile-menu-heading-color);
            --message-bar-accent-color: var(--mobile-menu-accent-color);
            
            --logo-height: 3rem;
            --mobile-logo-height: calc(var(--logo-height) * 0.85);
            --menu-logo-height: var(--logo-height);
            --hamburger-size: 1.35rem;
            
            --mobile-menu-padding: 2.25rem 2.75rem;

            position: fixed;
            left: 0;
            top: 0;
            right: 0;
            z-index: 9997;

            border-bottom: solid 1px var(--accent-color);
            
            .scroll-menu-bar {

                &.show {
                    top: -1px;
                }
                
                .full-menu {

                    display: none;

                    @include sf-media($from: tabl) {

                        display: grid;
                    }

                    &.always-mobile {

                        @include sf-media($from: tabl) {

                            display: none;
                        }
                    }
                }
                
                a:not([role="button"]) {

                    display: inline-block;
                    font-size: 0.95rem;
                    line-height: 1.3;
                    white-space: nowrap;
                    
                    &.logo {

                        font-size: 0;

                        img {

                            width: auto;
                            max-height: var(--mobile-logo-height);

                            @include sf-media($from: tabp) {

                                max-height: var(--logo-height);
                            }                            
                        }
                    }

                    &.hamburger {

                        font-size: var(--hamburger-size);
                        font-weight: 400;
                        color: var(--color);
                        line-height: 1;
                        text-decoration: none;

                        @include sf-media($from: tabl) {

                            display: none;
                        }
                        
                        i {
                            line-height: 1;
                        }

                        &.always-mobile {

                            @include sf-media($from: tabl) {

                                display: inline-block;
                            }
                        }
                    }

                    &.pps-button {

                        display: inline-block;
                        margin: 0;
                        border: 0;
                        padding: 0;
                        text-align: center;
                        text-decoration: none;
                        vertical-align: middle;
                        white-space: nowrap;
                        border-radius: 0.2rem;
                        cursor: pointer;
                        font-size: 0;
                        line-height: 0;
                        font-weight: 600;
                        width: 100%;
                        font-family: var(--sf-font-family-forms);
                        user-select: none;
                        overflow: hidden;
                        color: var(--sf-color-white);

                        span {

                            display: inline-block;
                            line-height: 1.9rem;
                            font-size: 0.75rem;
                            font-weight: 700;
                        }

                        span:first-child {

                            padding-left: 1rem;
                            padding-right: 0.35rem;
                            background-color: var(--sf-color-secondary);
                        }

                        span:last-child {

                            padding-right: 1rem;
                            padding-left: 0.35rem;
                            background-color: var(--sf-color-tertiary);
                        }
                    }
                }
            }
            
            .flyout-menu {

                display: block;
                width: 85vw;
                position: fixed;
                top: -1px;
                left: 100vw;
                height: calc(100vh + 1px);
                overflow-y: auto;
                z-index: 9999;
                padding: var(--mobile-menu-padding);

                background-color: var(--mobile-menu-background-color);
                color: var(--mobile-menu-color);

                strong, b {

                    color: var(--mobile-menu-bold-color);
                }

                h1, h2, h3, h4, h5, h6 {

                    color: var(--mobile-menu-heading-color);
                }

                a:not([role="button"]) {
                    color: var(--mobile-menu-anchor-color);
                }

                a:not([role="button"]):hover {
                    color: var(--mobile-menu-anchor-hover-color);
                }

                a:not([role="button"]) {

                    display: block;
                    font-size: 1rem;

                    & + a:not([role="button"]) {

                        margin-top: 0.5rem;
                    }

                    &.logo {

                        font-size: 0;
                        margin-bottom: 0.5rem;

                        img {
                            width: auto;
                            max-height: var(--menu-logo-height);
                        }
                    }
                }
                
                .menu-item-wrapper {

                    & > .submenu-wrapper {

                        margin-top: 0.5rem;

                        .fas.fa-triangle {

                            display: none;
                        }
                    }
                }

                &.show {

                    left: 15vw;

                    @include sf-animate(
                        $property: left,
                        $delay: 0,
                        $duration: 0.15s,
                        $kind: ease
                    );

                    @include sf-media($from: tabp) {

                        width: 23rem;
                        left: calc(100vw - 23rem);
                    }
                }
            }
            
            .message-bar {

                text-align: center;
                font-size: 0.75rem;
                padding: 0.65rem 1rem 0.5rem 1rem;
                background-color: var(--message-bar-background-color);
                color: var(--message-bar-color);

                p {

                    line-height: 1.2;
                }

                @include sf-media($from: tabl) {

                    padding: 0.85rem 1rem 0.75rem 1rem;
                    font-size: 0.95rem;
                }
                
                strong, b {

                    color: var(--message-bar-bold-color);
                }

                h1, h2, h3, h4, h5, h6 {

                    color: var(--message-bar-heading-color);
                }

                a:not([role="button"]) {
                    color: var(--message-bar-anchor-color);
                    text-decoration: underline;
                }

                a:not([role="button"]):hover {
                    color: var(--message-bar-anchor-hover-color);
                }
            }
        }
    }
}

.proplete-menu-overlay {

    display: block;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 100vw;
    z-index: 9998;
    background-color: rgba(0, 0, 0, 0.5);
    
    &.show {
        left: 0;
    }
}
