/**
 * @nuclide Placeholder Style
 * @section Forms > Inputs
 * @modifiers
 *  @include sf-placeholder - Style an input control placeholder.
 * @markup
 *  @include sf-placeholder { color: #252525; }
 */
@mixin sf-placeholder {

    // scss-lint:disable VendorPrefix.
    &::-moz-placeholder { @content; } // Firefox 19+
    &:-ms-input-placeholder { @content; } // IE 10+
    &::-ms-input-placeholder { @content; } // Edge
    &::-webkit-input-placeholder { @content; } // Safari and Chrome
}
