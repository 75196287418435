.page-block-content {

    .content-block {

        &.stack-block {

            // Base Overrides

            --zero-grid-cols: 1fr;
            --phab-grid-cols: var(--zero-grid-cols);
            --tabp-grid-cols: 1fr;
            --tabl-grid-cols: var(--tabp-grid-cols);
            --note-grid-cols: var(--tabl-grid-cols);
            --desk-grid-cols: var(--note-grid-cols);
            --elas-grid-cols: var(--desk-grid-cols);

            --zero-grid-row-gap: 1.5rem;
            --phab-grid-row-gap: var(--zero-grid-row-gap);
            --tabp-grid-row-gap: var(--phab-grid-row-gap);
            --tabl-grid-row-gap: var(--tabp-grid-row-gap);
            --note-grid-row-gap: var(--tabl-grid-row-gap);
            --desk-grid-row-gap: var(--note-grid-row-gap);
            --elas-grid-row-gap: var(--desk-grid-row-gap);

            --zero-grid-align: initial;
            --phab-grid-align: var(--zero-grid-align);
            --tabp-grid-align: var(--phab-grid-align);
            --tabl-grid-align: var(--tabp-grid-align);
            --note-grid-align: var(--tabl-grid-align);
            --desk-grid-align: var(--note-grid-align);
            --elas-grid-align: var(--desk-grid-align);
            
            // Custom

            --zero-grid-cell-side-padding: 0;
            --phab-grid-cell-side-padding: var(--zero-grid-cell-side-padding);
            --tabp-grid-cell-side-padding: var(--phab-grid-cell-side-padding);
            --tabl-grid-cell-side-padding: var(--tabp-grid-cell-side-padding);
            --note-grid-cell-side-padding: var(--tabl-grid-cell-side-padding);
            --desk-grid-cell-side-padding: var(--note-grid-cell-side-padding);
            --elas-grid-cell-side-padding: var(--desk-grid-cell-side-padding);

            --zero-grid-cell-vertical-padding: 0;
            --phab-grid-cell-vertical-padding: var(--zero-grid-cell-vertical-padding);
            --tabp-grid-cell-vertical-padding: var(--phab-grid-cell-vertical-padding);
            --tabl-grid-cell-vertical-padding: var(--tabp-grid-cell-vertical-padding);
            --note-grid-cell-vertical-padding: var(--tabl-grid-cell-vertical-padding);
            --desk-grid-cell-vertical-padding: var(--note-grid-cell-vertical-padding);
            --elas-grid-cell-vertical-padding: var(--desk-grid-cell-vertical-padding);
            
            & > .cb-overlay > .cb-elastic-wrapper > .cb-margin-wrapper > [sf-grid] {

                --sf-align-items: var(--zero-grid-align); --sf-phab-align-items: var(--phab-grid-align); --sf-tabp-align-items: var(--tabp-grid-align); --sf-tabl-align-items: var(--tabl-grid-align); --sf-note-align-items: var(--note-grid-align); --sf-desk-align-items: var(--desk-grid-align); --sf-elas-align-items: var(--elas-grid-align);
                
                & > [sf-grid-cell] > [role="presentation"] > [sf-grid] {

                    --sf-row-gap: var(--zero-grid-row-gap);
                    --sf-phab-row-gap: var(--phab-grid-row-gap);
                    --sf-tabp-row-gap: var(--tabp-grid-row-gap);
                    --sf-tabl-row-gap: var(--tabl-grid-row-gap);
                    --sf-note-row-gap: var(--note-grid-row-gap);
                    --sf-desk-row-gap: var(--desk-grid-row-gap);
                    --sf-elas-row-gap: var(--elas-grid-row-gap);
                }                
                
                & > [sf-grid-cell] > [role="presentation"] {

                    padding: var(--zero-grid-cell-vertical-padding) var(--zero-grid-cell-side-padding);

                    @include sf-media($from: phab) {
                        padding: var(--phab-grid-cell-vertical-padding) var(--phab-grid-cell-side-padding);
                    }

                    @include sf-media($from: tabp) {
                        padding: var(--tabp-grid-cell-vertical-padding) var(--tabp-grid-cell-side-padding);
                    }

                    @include sf-media($from: tabl) {
                        padding: var(--tabl-grid-cell-vertical-padding) var(--tabl-grid-cell-side-padding);
                    }

                    @include sf-media($from: note) {
                        padding: var(--note-grid-cell-vertical-padding) var(--note-grid-cell-side-padding);
                    }

                    @include sf-media($from: desk) {
                        padding: var(--desk-grid-cell-vertical-padding) var(--desk-grid-cell-side-padding);
                    }

                    @include sf-media($from: elas) {
                        padding: var(--elas-grid-cell-vertical-padding) var(--elas-grid-cell-side-padding);
                    }
                }
            }
        }
    }
}
