.page-block-content {

    .content-block {

        &.active-image-block {

            position: relative;
            height: 100%;
            width: 100%;
            overflow: hidden;
            
            // Base Overrides

            --background-color: transparent;

            // Custom

            --image-corner-radius: 0.25rem;
            --image-width: 100%;
            --image-alignment: left;

            border-radius: var(--image-corner-radius);

            --caption-color: var(--sf-color-secondary);
            
            --zero-margin: 0;
            --phab-margin: var(--zero-margin);
            --tabp-margin: var(--phab-margin);
            --tabl-margin: var(--tabp-margin);
            --note-margin: var(--tabl-margin);
            --desk-margin: var(--note-margin);
            --elas-margin: var(--desk-margin);
            --margin: var(--zero-margin);
            
            --mobile-min-height: 15rem;
            --min-height: 15rem;

            --mobile-image-width: 100%;            

            @include sf-media($from: phab) {
                --margin: var(--phab-margin);
            }

            @include sf-media($from: tabp) {
                --margin: var(--tabp-margin);
            }

            @include sf-media($from: tabl) {
                --margin: var(--tabl-margin);
            }

            @include sf-media($from: note) {
                --margin: var(--note-margin);
            }

            @include sf-media($from: desk) {
                --margin: var(--desk-margin);
            }

            @include sf-media($from: elas) {
                --margin: var(--elas-margin);
            }

            .cb-overlay {

                height: 100%;

                .cb-elastic-wrapper {

                    height: 100%;

                    .cb-margin-wrapper {

                        height: 100%;
                        text-align: var(--mobile-image-alignment);

                        @include sf-media($from: tabp) {
                            text-align: var(--image-alignment);
                        }
                    }
                }
            }
            
            [sf-image-wrapper] {

                --zero-width: var(--mobile-image-width);
                --phab-width: var(--zero-width);
                --tabp-width: var(--image-width);
                --tabl-width: var(--tabp-width);
                --note-width: var(--tabl-width);
                --desk-width: var(--note-width);
                --elas-width: var(--desk-width);
                --max-width: var(--zero-width);

                --zero-side-margins: 0;
                --phab-side-margins: 0;

                @include sf-media($from: phab) { --max-width: var(--phab-width); }
                @include sf-media($from: tabp) { --max-width: var(--tabp-width); }
                @include sf-media($from: tabl) { --max-width: var(--tabl-width); }
                @include sf-media($from: note) { --max-width: var(--note-width); }
                @include sf-media($from: desk) { --max-width: var(--desk-width); }
                @include sf-media($from: elas) { --max-width: var(--elas-width); }
                
                position: relative;
                display: block;
                max-width: var(--max-width);
                width: var(--max-width);
                margin: var(--margin);
                height: 100%;
                min-height: var(--mobile-min-height);

                overflow: hidden;

                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;

                @include sf-animate(
                    $property: transform,
                    $delay: 0,
                    $duration: 0.25s,
                    $kind: ease
                );

                &:hover {

                    transform: scale(1.1);
                    background-size: cover;
                }

                @include sf-media($from: tabp) {
                    min-height: var(--min-height);
                }
                
                &.pointer {

                    cursor: pointer;
                }
                
                .play-button-overlay {
                    position: absolute;
                    font-size: 5rem;
                    color: var(--sf-color-white);
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .caption {

                --heading-color: var(--caption-color);

                color: var(--caption-color);
                position: absolute;
                bottom: 1rem;
                left: 1rem;

                h1, h2, h3, h4, h5, h6 {

                    margin-top: 0;
                    margin-bottom: 0;
                }
                
                &.panel {

                    --heading-color: var(--sf-color-primary-contrast);

                    background-color: var(--sf-color-primary);
                    padding: 0.75rem;
                    border-radius: 0.5rem;
                    text-align: center;
                    width: 90%;
                    left: 50%;
                    bottom: 1rem;
                    transform: translateX(-50%);
                }
            }
        }
    }
}