.page-block-content {

    .content-block {

        &.proplete-strong-questionnaire-block {

            // Overrides

            --sf-color-radio-unchecked: rgb(200, 222, 230);
            
            // Custom

            --panel-bg-color: var(--background-color);
            --panel-fg-color: var(--color);
            --panel-bold-color: var(--bold-color);
            --panel-anchor-color: var(--anchor-color);
            --panel-anchor-hover-color: var(--anchor-hover-color);
            --panel-heading-color: var(--heading-color);
            
            .heading-bar {

                text-align: left;
                margin-bottom: 1.5rem;

                h1, h2, h3, h4, h5, h6 {

                    margin-bottom: 0;

                    span {

                        color: var(--sf-color-secondary);
                    }
                    
                    & + p {
                        
                        margin-top: 1rem;
                    }
                }
                
                .photo {
                    
                    @include sf-media($upto: tabp) {
                        
                        img {
                            width: 65%;
                            left: 50%;
                            transform: translateX(-50%);                            
                        }
                    }                    
                }
            }
            
            .question {
                
                margin-bottom: 1rem;
            }
            
            .panel {

                margin-top: 2.5rem;
                padding: 1.5rem;
                background-color: var(--panel-bg-color);
                color: var(--panel-fg-color);
                border-radius: 0.25rem;

                strong {

                    color: var(--panel-bold-color);
                }
                
                h1, h2, h3, h4, h5, h6 {

                    color: var(--panel-heading-color);
                }

                a:not([role="button"]) {

                    color: var(--panel-anchor-color);
                    
                    &:hover {

                        color: var(--panel-anchor-hover-color);
                    }
                }
            }
        }
    }
}
