.page-block-content {

    .content-block {

        &.portal-sign-in-block-zoia {

            // Overrides

            // Custom
            
            [sf-form] {

                [sf-validation-message] {
                    
                    color: var(--sf-color-danger);
                    padding-top: 1.5rem;
                }
            }
        }
    }
}
