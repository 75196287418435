.page-block-content {

    .content-block {

        &.mega-menu-header-block {

            // Base Overrides
            
            --top-margin: 0;
            --bottom-margin: 0;
            
            background-color: transparent;
            color: transparent;
            
            // Custom

            --eyebrow-bg-color: var(--sf-color-always-white);
            --eyebrow-anchor-color: var(--sf-color-secondary);
            --eyebrow-anchor-hover-color: var(--sf-color-primary);
            
            --menu-color: var(--sf-color-primary);
            --menu-color-contrast: var(--sf-color-primary-contrast);

            --mobile-menu-background-color: var(--background-color);
            --mobile-menu-color: var(--color);
            --mobile-menu-bold-color: var(--bold-color);
            --mobile-menu-anchor-color: var(--anchor-color);
            --mobile-menu-anchor-hover-color: var(--anchor-hover-color);
            --mobile-menu-heading-color: var(--heading-color);
            --mobile-menu-accent-color: var(--accent-color);
            
            --logo-height: 2rem;
            --menu-logo-height: var(--logo-height);
            --hamburger-size: 1.35rem;
            
            --mobile-menu-padding: 1.5rem 1.75rem;

            .scroll-nav-bar-placeholder {

                display: none;
                
                @include sf-media($from: tabp) {

                    display: block;
                }
            }
            
            .scroll-nav-bar {

                --zero-scroll-nav-side-margins: 0;
                --phab-scroll-nav-side-margins: var(--zero-scroll-nav-side-margins);
                --tabp-scroll-nav-side-margins: var(--phab-scroll-nav-side-margins);
                --tabl-scroll-nav-side-margins: var(--tabp-scroll-nav-side-margins);
                --note-scroll-nav-side-margins: var(--tabl-scroll-nav-side-margins);
                --desk-scroll-nav-side-margins: var(--note-scroll-nav-side-margins);
                --elas-scroll-nav-side-margins: var(--desk-scroll-nav-side-margins);
                --scroll-nav-side-margins: var(--zero-scroll-nav-side-margins);

                --zero-scroll-nav-top-margin: 0;
                --phab-scroll-nav-top-margin: var(--zero-scroll-nav-top-margin);
                --tabp-scroll-nav-top-margin: var(--phab-scroll-nav-top-margin);
                --tabl-scroll-nav-top-margin: var(--tabp-scroll-nav-top-margin);
                --note-scroll-nav-top-margin: var(--tabl-scroll-nav-top-margin);
                --desk-scroll-nav-top-margin: var(--note-scroll-nav-top-margin);
                --elas-scroll-nav-top-margin: var(--desk-scroll-nav-top-margin);
                --scroll-nav-top-margin: var(--zero-scroll-nav-top-margin);

                --zero-scroll-nav-bottom-margin: 0;
                --phab-scroll-nav-bottom-margin: var(--zero-scroll-nav-bottom-margin);
                --tabp-scroll-nav-bottom-margin: var(--phab-scroll-nav-bottom-margin);
                --tabl-scroll-nav-bottom-margin: var(--tabp-scroll-nav-bottom-margin);
                --note-scroll-nav-bottom-margin: var(--tabl-scroll-nav-bottom-margin);
                --desk-scroll-nav-bottom-margin: var(--note-scroll-nav-bottom-margin);
                --elas-scroll-nav-bottom-margin: var(--desk-scroll-nav-bottom-margin);
                --scroll-nav-bottom-margin: var(--zero-scroll-nav-bottom-margin);

                --zero-scroll-nav-elastic-width: 100%;
                --phab-scroll-nav-elastic-width: var(--zero-scroll-nav-elastic-width);
                --tabp-scroll-nav-elastic-width: var(--phab-scroll-nav-elastic-width);
                --tabl-scroll-nav-elastic-width: var(--tabp-scroll-nav-elastic-width);
                --note-scroll-nav-elastic-width: var(--tabl-scroll-nav-elastic-width);
                --desk-scroll-nav-elastic-width: var(--note-scroll-nav-elastic-width);
                --elas-scroll-nav-elastic-width: var(--desk-scroll-nav-elastic-width);
                --scroll-nav-elastic-width: var(--zero-scroll-nav-elastic-width);

                display: none;
                position: fixed;
                left: 0;
                top: 0;
                right: 0;
                padding: 0;
                z-index: 9997;

                backdrop-filter: saturate(180%) blur(1rem);
                -webkit-backdrop-filter: saturate(180%) blur(1rem);

                background-color: var(--bar-color);
                color: var(--menu-item-color);
                border-bottom: solid 1px rgba(0, 0, 0, 0.03);

                &.animate {

                    @include sf-animate(
                        $property: transform,
                        $delay: 0,
                        $duration: 0.15s,
                        $kind: ease
                    );
                }
                
                @include sf-media($from: phab) {

                    --scroll-nav-top-margin: var(--phab-scroll-nav-top-margin);
                    --scroll-nav-bottom-margin: var(--phab-scroll-nav-bottom-margin);
                    --scroll-nav-elastic-width: var(--phab-scroll-nav-elastic-width);
                    --scroll-nav-side-margins: var(--phab-scroll-nav-side-margins);
                }

                @include sf-media($from: tabp) {

                    display: block;

                    --scroll-nav-top-margin: var(--tabp-scroll-nav-top-margin);
                    --scroll-nav-bottom-margin: var(--tabp-scroll-nav-bottom-margin);
                    --scroll-nav-elastic-width: var(--tabp-scroll-nav-elastic-width);
                    --scroll-nav-side-margins: var(--tabp-scroll-nav-side-margins);
                }

                @include sf-media($from: tabl) {

                    --scroll-nav-top-margin: var(--tabl-scroll-nav-top-margin);
                    --scroll-nav-bottom-margin: var(--tabl-scroll-nav-bottom-margin);
                    --scroll-nav-elastic-width: var(--tabl-scroll-nav-elastic-width);
                    --scroll-nav-side-margins: var(--tabl-scroll-nav-side-margins);
                }

                @include sf-media($from: note) {

                    --scroll-nav-top-margin: var(--note-scroll-nav-top-margin);
                    --scroll-nav-bottom-margin: var(--note-scroll-nav-bottom-margin);
                    --scroll-nav-elastic-width: var(--note-scroll-nav-elastic-width);
                    --scroll-nav-side-margins: var(--note-scroll-nav-side-margins);
                }

                @include sf-media($from: desk) {

                    --scroll-nav-top-margin: var(--desk-scroll-nav-top-margin);
                    --scroll-nav-bottom-margin: var(--desk-scroll-nav-bottom-margin);
                    --scroll-nav-elastic-width: var(--desk-scroll-nav-elastic-width);
                    --scroll-nav-side-margins: var(--desk-scroll-nav-side-margins);
                }

                @include sf-media($from: elas) {

                    --scroll-nav-top-margin: var(--elas-scroll-nav-top-margin);
                    --scroll-nav-bottom-margin: var(--elas-scroll-nav-bottom-margin);
                    --scroll-nav-elastic-width: var(--elas-scroll-nav-elastic-width);
                    --scroll-nav-side-margins: var(--elas-scroll-nav-side-margins);
                }
                
                strong, b {

                    color: var(--menu-item-color);
                }

                h1, h2, h3, h4, h5, h6 {

                    color: var(--menu-item-color);
                }

                a:not([role="button"]) {

                    display: block;
                    color: var(--menu-item-color);

                    span {
                        font-size: calc(var(--font-size) * 0.90);
                        font-weight: 500;
                    }

                    @include sf-media($from: tabl) {

                        span {
                            font-size: calc(var(--font-size) * 1.1);
                        }
                    }                    
                    
                    &:hover {
                        text-decoration: none;
                    }

                    &.hover-underline:hover {
                        text-decoration: underline;
                    }
                    
                    &.logo {

                        font-size: 0;
                        
                        &.nudge-left {
                            margin-left: -0.75rem;
                        }

                        img {
                            width: auto;
                            max-height: var(--logo-height);
                        }
                    }
                }
                
                a:not([role="button"]):hover {
                    color: var(--menu-item-color);
                }

                .scroll-nav-bar-inner {

                    margin: 0 auto;
                    max-width: var(--scroll-nav-elastic-width);
                    padding-top: var(--scroll-nav-top-margin);
                    padding-bottom: var(--scroll-nav-bottom-margin);
                    padding-left: var(--scroll-nav-side-margins);
                    padding-right: var(--scroll-nav-side-margins);

                    .fa-triangle {

                        display: none;
                        position: absolute;
                        font-size: 1rem;
                        line-height: 1;
                        left: 50%;
                        padding-top: 0.035rem;
                        transform: translate(-50%, 100%);
                        color: var(--sf-color-secondary-translucent);
                        
                        &.open {
                            display: inline-block;
                        }
                    }
                    
                    .mega-menu {

                        display: none;
                        position: fixed;
                        overflow: hidden;
                        left: 0;
                        width: 100vw;
                        top: 100%;
                        z-index: 9998;
                        line-height: 0;
                        background-color: var(--sf-color-secondary-translucent);

                        &.open {
                            display: block;
                        }
                        
                        .menu-inner {
                            
                            --h6-margin-bottom: 0.75rem;
                            --paragraph-spacing: 0.5rem;
                            
                            position: relative;
                            margin: 0 auto;
                            max-width: var(--scroll-nav-elastic-width);
                            padding: 2rem var(--scroll-nav-side-margins);

                            .mega-menu-bg {
                                position: absolute;
                                top: 0;
                                left: calc((100% - 4rem) * -1);
                                width: 100%;
                            }
                            
                            .column-heading {
                                
                                padding-bottom: 0.75rem;
                                border-bottom: solid 1px rgba(255, 255, 255, 0.15);
                                margin-bottom: 1rem;
                            }
                            
                            .flex-container {
                                display: flex;
                                flex-direction: row;
                                flex-wrap: nowrap;
                                gap: 2rem;
                            }

                            .flex-item {

                                flex: 0 1 auto;
                                min-width: min-content;
                                max-width: max-content;

                                & * {
                                    word-break: keep-all;
                                }
                                
                                .show-as-heading {
                                    
                                    display: inline-block;
                                    text-align: center;
                                    
                                    .icon {
                                        max-width: 3rem;
                                        max-height: 3rem;
                                        margin-bottom: 1rem;
                                    }
                                }
                            }
                            
                            [vertical-line] {
                                
                                height: 100%;
                                border-right-width: 3px;
                                border-right-style: solid;
                                border-right-color: rgba(255, 255, 255, 0.25);
                            }

                            h4, h5, p {
                                cursor: default;
                            }

                            a:not([role="button"]) {

                                --line-height: 1.3;

                                cursor: pointer;
                                color: var(--sf-color-secondary-contrast);
                                opacity: 0.75;

                                span {
                                    font-size: var(--font-size);
                                    font-weight: 400;
                                }
                                
                                &:hover {
                                    text-decoration: underline;
                                    opacity: 1.0;
                                }
                            }

                            h6 > a:not([role="button"]) {
                                opacity: 1.0;
                            }
                        }
                    }
                }
            }

            .eyebrow-nav-bar {

                --zero-scroll-nav-side-margins: 0;
                --phab-scroll-nav-side-margins: var(--zero-scroll-nav-side-margins);
                --tabp-scroll-nav-side-margins: var(--phab-scroll-nav-side-margins);
                --tabl-scroll-nav-side-margins: var(--tabp-scroll-nav-side-margins);
                --note-scroll-nav-side-margins: var(--tabl-scroll-nav-side-margins);
                --desk-scroll-nav-side-margins: var(--note-scroll-nav-side-margins);
                --elas-scroll-nav-side-margins: var(--desk-scroll-nav-side-margins);
                --scroll-nav-side-margins: var(--zero-scroll-nav-side-margins);

                --zero-scroll-nav-top-margin: 0;
                --phab-scroll-nav-top-margin: var(--zero-scroll-nav-top-margin);
                --tabp-scroll-nav-top-margin: var(--phab-scroll-nav-top-margin);
                --tabl-scroll-nav-top-margin: var(--tabp-scroll-nav-top-margin);
                --note-scroll-nav-top-margin: var(--tabl-scroll-nav-top-margin);
                --desk-scroll-nav-top-margin: var(--note-scroll-nav-top-margin);
                --elas-scroll-nav-top-margin: var(--desk-scroll-nav-top-margin);
                --scroll-nav-top-margin: var(--zero-scroll-nav-top-margin);

                --zero-scroll-nav-bottom-margin: 0;
                --phab-scroll-nav-bottom-margin: var(--zero-scroll-nav-bottom-margin);
                --tabp-scroll-nav-bottom-margin: var(--phab-scroll-nav-bottom-margin);
                --tabl-scroll-nav-bottom-margin: var(--tabp-scroll-nav-bottom-margin);
                --note-scroll-nav-bottom-margin: var(--tabl-scroll-nav-bottom-margin);
                --desk-scroll-nav-bottom-margin: var(--note-scroll-nav-bottom-margin);
                --elas-scroll-nav-bottom-margin: var(--desk-scroll-nav-bottom-margin);
                --scroll-nav-bottom-margin: var(--zero-scroll-nav-bottom-margin);

                --zero-scroll-nav-elastic-width: 100%;
                --phab-scroll-nav-elastic-width: var(--zero-scroll-nav-elastic-width);
                --tabp-scroll-nav-elastic-width: var(--phab-scroll-nav-elastic-width);
                --tabl-scroll-nav-elastic-width: var(--tabp-scroll-nav-elastic-width);
                --note-scroll-nav-elastic-width: var(--tabl-scroll-nav-elastic-width);
                --desk-scroll-nav-elastic-width: var(--note-scroll-nav-elastic-width);
                --elas-scroll-nav-elastic-width: var(--desk-scroll-nav-elastic-width);
                --scroll-nav-elastic-width: var(--zero-scroll-nav-elastic-width);
                
                display: none;
                position: fixed;
                left: 0;
                top: 0;
                right: 0;
                padding: 0;
                z-index: 9997;

                backdrop-filter: saturate(180%) blur(1rem);
                -webkit-backdrop-filter: saturate(180%) blur(1rem);
                
                background-color: var(--eyebrow-bg-color);

                &.animate {

                    @include sf-animate(
                        $property: transform,
                        $delay: 0,
                        $duration: 0.15s,
                        $kind: ease
                    );
                }
                
                @include sf-media($from: phab) {

                    --scroll-nav-top-margin: var(--phab-scroll-nav-top-margin);
                    --scroll-nav-bottom-margin: var(--phab-scroll-nav-bottom-margin);
                    --scroll-nav-elastic-width: var(--phab-scroll-nav-elastic-width);
                    --scroll-nav-side-margins: var(--phab-scroll-nav-side-margins);
                }

                @include sf-media($from: tabp) {

                    display: block;
                    
                    --scroll-nav-top-margin: var(--tabp-scroll-nav-top-margin);
                    --scroll-nav-bottom-margin: var(--tabp-scroll-nav-bottom-margin);
                    --scroll-nav-elastic-width: var(--tabp-scroll-nav-elastic-width);
                    --scroll-nav-side-margins: var(--tabp-scroll-nav-side-margins);
                }

                @include sf-media($from: tabl) {

                    --scroll-nav-top-margin: var(--tabl-scroll-nav-top-margin);
                    --scroll-nav-bottom-margin: var(--tabl-scroll-nav-bottom-margin);
                    --scroll-nav-elastic-width: var(--tabl-scroll-nav-elastic-width);
                    --scroll-nav-side-margins: var(--tabl-scroll-nav-side-margins);
                }

                @include sf-media($from: note) {

                    --scroll-nav-top-margin: var(--note-scroll-nav-top-margin);
                    --scroll-nav-bottom-margin: var(--note-scroll-nav-bottom-margin);
                    --scroll-nav-elastic-width: var(--note-scroll-nav-elastic-width);
                    --scroll-nav-side-margins: var(--note-scroll-nav-side-margins);
                }

                @include sf-media($from: desk) {

                    --scroll-nav-top-margin: var(--desk-scroll-nav-top-margin);
                    --scroll-nav-bottom-margin: var(--desk-scroll-nav-bottom-margin);
                    --scroll-nav-elastic-width: var(--desk-scroll-nav-elastic-width);
                    --scroll-nav-side-margins: var(--desk-scroll-nav-side-margins);
                }

                @include sf-media($from: elas) {

                    --scroll-nav-top-margin: var(--elas-scroll-nav-top-margin);
                    --scroll-nav-bottom-margin: var(--elas-scroll-nav-bottom-margin);
                    --scroll-nav-elastic-width: var(--elas-scroll-nav-elastic-width);
                    --scroll-nav-side-margins: var(--elas-scroll-nav-side-margins);
                }
                
                .eyebrow-nav-bar-inner {

                    margin: 0 auto;
                    max-width: var(--scroll-nav-elastic-width);
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;
                    padding-left: var(--scroll-nav-side-margins);
                    padding-right: var(--scroll-nav-side-margins);

                    a:not([role="button"]) {

                        line-height: 1.2;
                        max-height: 1.3rem;
                        color: var(--eyebrow-anchor-color);
                        display: block;
                        overflow: hidden;

                        span {
                            
                            &, & i {
                                font-size: calc(var(--font-size) * 0.9);
                                line-height: 1.2;
                            }
                            
                            i {
                                margin-right: 0.5rem;
                            }
                        }

                        svg {

                            display: inline-block;
                            position: relative;
                            top: 0.0625rem;
                            width: 0.9rem;
                            height: 0.9rem;
                            margin-right: 0.5rem;

                            &, g {

                                fill: var(--mobile-menu-color);

                                @include sf-media($from: tabp) {
                                    fill: var(--eyebrow-anchor-color);
                                }
                            }
                        }

                        &:hover {

                            color: var(--eyebrow-anchor-hover-color);

                            svg {

                                &, g {

                                    @include sf-media($from: tabp) {
                                        fill: var(--eyebrow-anchor-hover-color);
                                    }
                                }
                            }
                        }
                    }

                    .social-icons-grid {

                        line-height: 0;
                        
                        a:not([role="button"]) {

                            color: var(--eyebrow-anchor-color);
                            line-height: 0;

                            &:hover {
                                color: var(--eyebrow-anchor-hover-color);
                            }
                            
                            span {

                                line-height: 0;

                                i {
                                    margin-right: 0;
                                    line-height: 1;
                                }
                            }
                        }
                    }                    
                }
            }
        }

        &.mega-menu-header-block [ns-mobile-buffer] {

            display: none;

            @include sf-media($upto: tabp) {

                display: block;
                position: fixed;
                z-index: 10001;
                overflow: hidden;
                top: 0;
                transform: translateY(-4rem);
                left: 0;
                width: 100vw;
                height: 8rem;
                transition: transform 0.2s;
            }
        }

        &.mega-menu-header-block [ns-mobile] {

            --zero-side-margins: 1.25rem;
            
            display: none;

            @include sf-media($upto: tabp) {

                display: block;
                position: fixed;
                z-index: 10002;
                overflow: auto;
                max-height: calc(100vh + 10px);
                max-height: calc(100dvh + 10px);
                top: -10px;
                transform: translateY(0);
                left: 0;
                width: 100vw;
                padding: 0 var(--zero-side-margins);
                transition: transform 0.2s;

                backdrop-filter: saturate(180%) blur(1rem);
                -webkit-backdrop-filter: saturate(180%) blur(1rem);
                
                background-color: var(--mobile-menu-background-color);
                color: var(--mobile-menu-color);
                border-top: solid 10px var(--mobile-menu-background-color);
                
                [ns-menu-content] {

                    overflow-y: visible;
                    min-height: 0;
                    max-height: 0;
                    transition: opacity 0.2s, min-height 0.2s, max-height 0.2s, padding 0.2s;
                    opacity: 1.0;
                    
                    h6 {
                        display: inline-block;
                    }
                }

                &.show-menu {

                    min-height: calc(100vh + 10px);
                    min-height: calc(100dvh + 10px);
                    
                    [ns-menu-content] {
                        max-height: 200vh;
                        min-height: 1rem;
                        padding-top: 1.5rem;
                        padding-bottom: 1.5rem;
                    }
                }
                
                [ns-tabbar] {

                    --font-size: 1.5rem;

                    font-size: var(--font-size);
                    opacity: 1.0;
                    transition: opacity 0.2s;

                    p {
                        line-height: 1;
                    }
                    
                    .logo {

                        display: inline-block;
                        padding-top: var(--zero-side-margins);
                        padding-bottom: var(--zero-side-margins);
                        line-height: 1;
                        font-size: 0;
                    }
                }

                &.hidden {

                    [ns-tabbar], [ns-menu-content] {

                        opacity: 0;
                    }
                }

                [ns-menu-content] {

                    a {

                        font-size: 1rem;

                        i {
                            font-size: 1.15rem;
                            margin-right: 0.5rem;
                        }
                        
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }

                p, strong, strong, a, i, em, h1, h2, h3, h4, h5, h6 {
                    --color: var(--mobile-menu-color);
                    --bold-color: var(--mobile-menu-color);
                    --heading-color: var(--mobile-menu-color);
                    --anchor-color: var(--mobile-menu-color);
                    --anchor-hover-color: var(--mobile-menu-color);
                }

                a[role="button"] {

                    &.mobile-submenu-button {

                        background-color: var(--mobile-menu-button-color);
                        color: var(--mobile-menu-button-text-color);
                        vertical-align: baseline;
                        width: auto;
                        padding: 0 0.35rem;
                        margin-left: 0.5rem;

                        span {
                        
                            padding: 0.15rem 0;
                        }
                        
                        i {
                            line-height: 1;
                            margin-right: 0;
                            font-size: 0.85rem;
                        }
                    }
                }                

                .mobile-submenu {

                    overflow-y: auto;
                    min-height: 0;
                    max-height: 0;
                    transition: opacity 0.5s, max-height 0.2s;
                    opacity: 0;
                    
                    &.open {
                        max-height: 100vh;
                        opacity: 1.0;
                    }
                    
                    .mobile-submenu-inner {

                        padding-top: 0.5rem;
                        padding-left: 1.25rem;
                        border-left: solid 1px rgba(255, 255, 255, 0.25);
                        margin-left: 0.25rem;
                        margin-top: 0.5rem;
                        
                        a:not([role="button"]) {

                            display: block;
                            opacity: 0.75;
                            font-weight: normal;
                            @include sf-line-clamp(1);

                            &:hover {
                                text-decoration: none;
                                opacity: 1.0;
                            }
                        }
                        
                        h6 {
                            & > a.show-as-heading {
                                opacity: 1.0;
                                font-weight: var(--h6-font-weight);                                
                            }
                        }
                    }
                }
                
                .eyebrow-items {

                    background-color: #00000010;
                    border-radius: 0.25rem;
                    padding: 1.5rem 1.5rem;
                    overflow: hidden;

                    a:not([role="button"]) {

                        display: block;
                        font-size: 1rem;
                        @include sf-line-clamp(1);

                        &:hover {
                            text-decoration: none;
                        }

                        svg {

                            display: inline-block;
                            position: relative;
                            top: 0.1625rem;
                            width: 1.15rem;
                            height: 1.15rem;
                            margin-right: 0.5rem;

                            &, g {

                                fill: var(--mobile-menu-color);

                                @include sf-media($from: tabp) {
                                    fill: var(--eyebrow-anchor-color);
                                }
                            }
                        }
                    }
                }

                .social-icons-grid {

                    background-color: #00000010;
                    border-radius: 0.25rem;
                    padding: 1.5rem 1.5rem;
                    overflow: hidden;

                    line-height: 0;

                    a:not([role="button"]) {

                        line-height: 0;

                        &:hover {
                            text-decoration: none;
                        }
                        
                        i {
                            line-height: 1;
                            margin-right: 0;
                        }
                    }
                }
            }
        }

        &.mega-menu-header-block [ns-mobile-overlay] {

            display: none;

            @include sf-media($upto: tabp) {

                display: block;
                position: fixed;
                top: 100vh;
                left: 100vw;
                width: 100vw;
                height: 100vh;
                overflow: hidden;
                background-color: rgba(0,0,0,0.8);
                opacity: 0;
                -webkit-overflow-scrolling: touch;
                z-index: 10001;
                backdrop-filter: saturate(180%) blur(0.15rem);
                -webkit-backdrop-filter: saturate(180%) blur(0.15rem);

                @include sf-animate(
                    $property: opacity,
                    $delay: 0s,
                    $duration: 0.75s,
                    $kind: ease
                );

                &.active {

                    top: 0;
                    left: 0;
                }

                &.fade {
                    opacity: 1.0;
                }
            }
        }
    }
}

html, body {

    background-color: var(--sf-color-primary) !important;
    
    @include sf-media($from: tabp) {
        background-color: var(--background-color) !important;
    }
}

main {
    background-color: var(--background-color);
}
