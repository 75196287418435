table {

  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  &:last-child {

    margin-bottom: 0;
  }

  th, td {

    padding: 0;
    margin: 0;
    text-align: left;
    padding-top: 0.5rem;
    padding-right: 1rem;

    @include sf-media($from: tabp) {

      padding-top: 0.75rem;
      padding-right: 1.25rem;
    }

    &:last-child {

      padding-right: 0;
    }
  }

  th {

    font-weight: bold;
  }
}
