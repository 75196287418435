.page-block-content {

    .content-block {

        &.insurance-form-block {

            [sf-form] {

                [sf-wrapper] {

                    & + small {
                        display: block;
                        margin-top: 0.25rem;
                    }
                }
            }

            [sf-form] {

                [sf-validation-message] {

                    color: var(--sf-color-danger);
                    line-height: 1.3;
                    padding-top: 0.75rem;
                }
                
                button > span > i {
                    margin-right: 1rem;
                }
            }
            
            .image-preview {

                margin-top: 1rem;
                max-width: 10rem;
                max-height: 10rem;
                overflow: hidden;
                border-radius: 0.25rem;
            }
        }
    }
}