.page-block-content {

    .content-block {

        &.payment-form-block {

            --sf-color-input-accent: var(--sf-color-input-placeholder);
            
            [sf-form] {

                [sf-validation-message] {

                    line-height: 1.3;
                }
                
                small {

                    display: block;
                    margin-top: 0.5rem;
                }

                [sf-checkbox] [sf-checkbox-label] {

                    font-family: var(--font-family);
                    display: inline-block;
                    width: auto;
                    padding-left: 0.5rem;
                }
            }
        }
    }
}
