.page-block-content {

    .content-block {

        &.spacer-block {

            // Base Overrides

            // Custom
            
            --zero-height: 0;
            --phab-height: var(--zero-height);
            --tabp-height: var(--phab-height);
            --tabl-height: var(--tabp-height);
            --note-height: var(--tabl-height);
            --desk-height: var(--note-height);
            --elas-height: var(--desk-height);
            
            .spacer {

                height: var(--zero-height);
                
                @include sf-media($from: phab) {

                    height: var(--phab-height);
                }
                
                @include sf-media($from: tabp) {

                    height: var(--tabp-height);
                }

                @include sf-media($from: tabl) {

                    height: var(--tabl-height);
                }
                
                @include sf-media($from: note) {

                    height: var(--note-height);
                }

                @include sf-media($from: desk) {

                    height: var(--desk-height);
                }

                @include sf-media($from: elas) {

                    height: var(--elas-height);
                }
            }
        }
    }
}