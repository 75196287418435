@mixin sf-generate-css-color-property($name: "default", $hex-color: #ffffff, $alpha: 1.0, $hex-contrast-color: #000000, $contrast-alpha: 1.0) {

    --sf-color-#{$name}: #{rgba($hex-color, $alpha)};
    --sf-color-#{$name}-contrast: #{rgba($hex-contrast-color, $contrast-alpha)};
}

// COLOR SETS
// Collections of colors with defined contrast, tint, and shade variations
// to help with using them as foreground and background colors.
//
:root {

    // CLEAR
    @include sf-generate-css-color-property(
        $name: "clear",
        $hex-color: transparent,
        $hex-contrast-color: #000000,
    );
    
    // PRIMARY
    @include sf-generate-css-color-property(
        $name: "primary", 
        $hex-color: #0072ad, 
        $hex-contrast-color: #ffffff,
    );

    // SECONDARY
    @include sf-generate-css-color-property(
        $name: "secondary", 
        $hex-color: #2da5db, 
        $hex-contrast-color: #ffffff,
    );

    // TERTIARY
    @include sf-generate-css-color-property(
        $name: "tertiary", 
        $hex-color: #7044ff, 
        $hex-contrast-color: #ffffff,
    );

    // ANCHORS
    @include sf-generate-css-color-property(
        $name: "anchors",
        $hex-color: #3880ff,
        $hex-contrast-color: #ffffff,
    );

    // BUTTONS
    @include sf-generate-css-color-property(
        $name: "buttons", 
        $hex-color: #27b36b, 
        $hex-contrast-color: #ffffff,
    );

    // SUCCESS
    @include sf-generate-css-color-property(
        $name: "success",
        $hex-color: #27b36b,
        $hex-contrast-color: #ffffff,
    );

    // WARNING
    @include sf-generate-css-color-property(
        $name: "warning", 
        $hex-color: #ffce00, 
        $hex-contrast-color: #000000,
    );

    // DANGER
    @include sf-generate-css-color-property(
        $name: "danger", 
        $hex-color: #f04141, 
        $hex-contrast-color: #ffffff,
    );

    // WHITE
    @include sf-generate-css-color-property(
        $name: "white",
        $hex-color: #ffffff,
        $hex-contrast-color: #000000,
    );

    // BLACK
    @include sf-generate-css-color-property(
        $name: "black",
        $hex-color: #000000,
        $hex-contrast-color: #ffffff,
    );
    
    // LIGHT
    @include sf-generate-css-color-property(
        $name: "light", 
        $hex-color: #f3f3f3, 
        $hex-contrast-color: #000000,
    );

    // MEDIUM
    @include sf-generate-css-color-property(
        $name: "medium", 
        $hex-color: #bbbbbb, 
        $hex-contrast-color: #ffffff,
    );

    // DARK
    @include sf-generate-css-color-property(
        $name: "dark", 
        $hex-color: #222428, 
        $hex-contrast-color: #ffffff,
    );

    // CODE
    @include sf-generate-css-color-property(
        $name: "code", 
        $hex-color: #2d2d2d, 
        $hex-contrast-color: #ffffff,
    );
    
    // Form select caret
    @include sf-generate-css-color-property(
        $name: "select-caret",
        $hex-color: #ababab,
        $hex-contrast-color: #000000,
    );

    // Form search icon
    @include sf-generate-css-color-property(
        $name: "search-icon",
        $hex-color: #ababab,
        $hex-contrast-color: #000000,
    );
    
    // General line color
    @include sf-generate-css-color-property(
        $name: "line",
        $hex-color: #e8e8e8,
        $hex-contrast-color: #000000,
    );

    // Form field color assignments
    @include sf-generate-css-color-property(
        $name: "form-field-line",
        $hex-color: #000000, $alpha: 0.1,
        $hex-contrast-color: #000000,
    );
    
    // INPUT
    @include sf-generate-css-color-property(
        $name: "input",
        $hex-color: #ffffff,
        $hex-contrast-color: #000000,
    );

    @include sf-generate-css-color-property(
            $name: "input-placeholder",
            $hex-color: #cdcdcd,
    );
    
    // TOGGLE
    @include sf-generate-css-color-property(
        $name: "toggle-unchecked",
        $hex-color: #e5e5e5,
        $hex-contrast-color: #ffffff,
    );

    // TOGGLE - CHECKED
    @include sf-generate-css-color-property(
        $name: "toggle-checked",
        $hex-color: #3880ff,
        $hex-contrast-color: #ffffff,
    );
    
    // RADIO
    @include sf-generate-css-color-property(
        $name: "radio-unchecked",
        $hex-color: #e5e5e5,
        $hex-contrast-color: #e0e0e0,
    );

    // RADIO - CHECKED
    @include sf-generate-css-color-property(
        $name: "radio-checked",
        $hex-color: #3880ff,
        $hex-contrast-color: #ffffff,
    );

    // REQUIRED FIELD MARKER
    @include sf-generate-css-color-property(
            $name: "required-field-marker",
            $hex-color: #f04141,
            $hex-contrast-color: #ffffff,
    );
}
