.page-block-content {

    .content-block {

        &.portal-sign-up-block-zoia {

            // Overrides

            // Custom
            
            [sf-form] {

                [sf-validation-message] {
                    
                    color: var(--sf-color-danger);
                }
            }
        }
    }
}
