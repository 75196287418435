.page-block-content {

    .content-block {

        &.page-collection-block {

            // Overrides

            // Custom

            --background-color: var(--sf-color-light);
            --accent-color: var(--sf-color-white);
            --color: var(--sf-color-black);

            background-color: var(--background-color);
            color: var(--color);

            h3 {

                margin-bottom: 3rem;
            }

            .not-found {

                text-align: center;

                h3 {
                    margin-bottom: 0.5rem;
                }
            }

            .panel {

                background-color: var(--accent-color);
                color: var(--color);
                padding: 1.5rem;
                overflow: hidden;
                border-radius: 0.35rem;

                img {

                    overflow: hidden;
                    border-radius: 0.25rem;
                    border: solid 1px rgba(0,0,0,0.07);
                }

                .file-icon {

                    position: relative;
                    height: 100%;
                    width: 100%;
                    overflow: hidden;
                    padding: 1.5rem;
                    border-radius: 0.25rem;
                    background-color: var(--sf-color-primary);

                    i {
                        position: relative;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        font-size: 4rem;
                        color: var(--sf-color-primary-contrast);
                    }
                }

                h4 {

                    margin-bottom: 1rem;

                    @include sf-media($from: tabp) {

                        @include sf-line-clamp($lines: 2);
                    }
                }

                p {

                    @include sf-media($from: tabp) {

                        @include sf-line-clamp($lines: 3);
                    }

                    &:not(:last-child) {
                        margin-bottom: 1rem;
                    }
                }

                .dateline {

                    span {

                        display: inline-block;
                        opacity: 0.4;

                        &.bar {
                            margin: 0 0.75rem;
                            opacity: 0.25;
                        }
                    }
                }

                a:not([role="button"]) {

                    text-decoration: none;

                    &:hover {

                        text-decoration: underline;
                    }
                }
            }

            .pagination {

                margin-top: 3rem;
                text-align: center;

                a:not([role="button"]) {

                    position: relative;
                    display: inline-block;
                    margin: 0.25rem;
                    text-decoration: none;
                    width: 2.5rem;
                    height: 2.5rem;
                    background-color: var(--sf-color-primary);
                    color: var(--sf-color-primary-contrast);
                    border-radius: 0.15rem;
                    line-height: 0;
                    font-size: 0;

                    &:hover {
                        background-color: var(--sf-color-secondary);
                        color: var(--sf-color-secondary-contrast);
                    }

                    &.current {

                        opacity: 0.25;
                    }

                    & > span {

                        position: absolute;
                        display: inline-block;
                        top: 55%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        white-space: nowrap;
                        line-height: 1;
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}
