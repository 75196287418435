.page-block-content {

    .content-block {

        // FULL WIDTH CONTENT WIDTHS 
        // ------------------------------------------------------------------
        // Makes content blocks full width.

        --zero-full-width: 100%;
        --phab-full-width: var(--zero-full-width);
        --tabp-full-width: var(--phab-full-width);
        --tabl-full-width: var(--tabp-full-width);
        --note-full-width: var(--tabl-full-width);
        --desk-full-width: var(--note-full-width);
        --elas-full-width: var(--desk-full-width);
        
        // Set defaults 

        --zero-side-margins: 0;
        --phab-side-margins: var(--zero-side-margins);
        --tabp-side-margins: var(--phab-side-margins);
        --tabl-side-margins: var(--tabp-side-margins);
        --note-side-margins: var(--tabl-side-margins);
        --desk-side-margins: var(--note-side-margins);
        --elas-side-margins: var(--desk-side-margins);
        --side-margins: var(--zero-side-margins);

        --zero-top-margin: 0;
        --phab-top-margin: var(--zero-top-margin);
        --tabp-top-margin: var(--phab-top-margin);
        --tabl-top-margin: var(--tabp-top-margin);
        --note-top-margin: var(--tabl-top-margin);
        --desk-top-margin: var(--note-top-margin);
        --elas-top-margin: var(--desk-top-margin);
        --top-margin: var(--zero-top-margin);

        --zero-bottom-margin: 0;
        --phab-bottom-margin: var(--zero-bottom-margin);
        --tabp-bottom-margin: var(--phab-bottom-margin);
        --tabl-bottom-margin: var(--tabp-bottom-margin);
        --note-bottom-margin: var(--tabl-bottom-margin);
        --desk-bottom-margin: var(--note-bottom-margin);
        --elas-bottom-margin: var(--desk-bottom-margin);
        --bottom-margin: var(--zero-bottom-margin);

        --zero-elastic-width: 100%;
        --phab-elastic-width: var(--zero-elastic-width);
        --tabp-elastic-width: var(--phab-elastic-width);
        --tabl-elastic-width: var(--tabp-elastic-width);
        --note-elastic-width: var(--tabl-elastic-width);
        --desk-elastic-width: var(--note-elastic-width);
        --elas-elastic-width: var(--desk-elastic-width);
        --elastic-width: var(--zero-elastic-width);

        --background-image: none;
        --dark-mode-background-image: var(--background-image);
        --bg-image: var(--background-image);

        --background-position: center;
        --background-size: 100% 100%;

        --overlay-color: transparent;

        background-color: var(--background-color);
        color: var(--color);

        background-image: var(--bg-image);
        background-position: var(--background-position);
        background-size: var(--background-size);
        background-repeat: no-repeat;

        @include sf-media($from: phab) {

            --top-margin: var(--phab-top-margin);
            --bottom-margin: var(--phab-bottom-margin);
        }

        @include sf-media($from: tabp) {

            --top-margin: var(--tabp-top-margin);
            --bottom-margin: var(--tabp-bottom-margin);
        }

        @include sf-media($from: tabl) {

            --top-margin: var(--tabl-top-margin);
            --bottom-margin: var(--tabl-bottom-margin);
        }

        @include sf-media($from: note) {

            --top-margin: var(--note-top-margin);
            --bottom-margin: var(--note-bottom-margin);
        }

        @include sf-media($from: desk) {

            --top-margin: var(--desk-top-margin);
            --bottom-margin: var(--desk-bottom-margin);
        }

        @include sf-media($from: elas) {

            --top-margin: var(--elas-top-margin);
            --bottom-margin: var(--elas-bottom-margin);
        }

        .cb-overlay {

            background-color: var(--overlay-color);
            padding-top: var(--top-margin);
            padding-bottom: var(--bottom-margin);

            .cb-elastic-wrapper {

                --elastic-width: var(--zero-elastic-width);
                
                @include sf-media($from: phab) {

                    --elastic-width: var(--phab-elastic-width);
                }

                @include sf-media($from: tabp) {

                    --elastic-width: var(--tabp-elastic-width);
                }

                @include sf-media($from: tabl) {

                    --elastic-width: var(--tabl-elastic-width);
                }

                @include sf-media($from: note) {

                    --elastic-width: var(--note-elastic-width);
                }

                @include sf-media($from: desk) {

                    --elastic-width: var(--desk-elastic-width);
                }

                @include sf-media($from: elas) {

                    --elastic-width: var(--elas-elastic-width);
                }

                margin: 0 auto;
                max-width: var(--elastic-width);

                .cb-margin-wrapper {

                    --side-margins: var(--zero-side-margins);
                    
                    @include sf-media($from: phab) {

                        --side-margins: var(--phab-side-margins);
                    }

                    @include sf-media($from: tabp) {

                        --side-margins: var(--tabp-side-margins);
                    }

                    @include sf-media($from: tabl) {

                        --side-margins: var(--tabl-side-margins);
                    }

                    @include sf-media($from: note) {

                        --side-margins: var(--note-side-margins);
                    }

                    @include sf-media($from: desk) {

                        --side-margins: var(--desk-side-margins);
                    }

                    @include sf-media($from: elas) {

                        --side-margins: var(--elas-side-margins);
                    }

                    position: relative;
                    padding: 0 var(--side-margins);
                }
            }
        }
    }
}

html.animate {

    p, span, strong, h1, h2, h3, h4, h5, h6 {
        transition-property: color;
        transition-duration: 0.5s;
        transition-timing-function: linear;
        animation-delay: 0s;
    }

    div {
        transition-property: background-color;
        transition-duration: 0.5s;
        transition-timing-function: linear;
        animation-delay: 0s;
    }
}

[dark-theme] {

    .page-block-content {

        .content-block {

            --bg-image: var(--dark-mode-background-image);
            --color-mask: var(--dark-mode-overlay-color);
        }
    }
}

@media (prefers-color-scheme: dark) {

    [auto-theme] {

        .page-block-content {

            .content-block {

                --bg-image: var(--dark-mode-background-image);
                --color-mask: var(--dark-mode-overlay-color);
            }
        }        
    }
}
