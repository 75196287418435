.page-block-content {

    .content-block {

        &.cookie-consent-block {

            // Base Overrides

            backdrop-filter: saturate(180%) blur(1rem);
            -webkit-backdrop-filter: saturate(180%) blur(1rem);

            --font-size: 0.75rem;

            font-size: var(--font-size) !important;
            
            // Custom
            
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            
            a[role="button"] {
                display: block;
                line-height: 0;
                & > i, & span {
                    line-height: calc(var(--font-size) * 0.85);
                    padding: 0.45rem 0;
                }
            }
            
            a.required-link {
                & > i {
                    margin-right: 0.5rem;
                }
            }
        }
    }
}
