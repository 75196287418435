.page-block-content {

    .content-block {

        // Name comes from block file name, hyphenated at capital letters
        &.block-template-block {

            // Overrides

            // Custom

            button, a[role=button], [sf-button] {

                border: solid 1px;
            }
        }
    }
}
