.page-block-content {

    .content-block {

        &.feature-panel-block {

            // Base Overrides

            // Custom

            --light-mode-bg-image: none;
            --dark-mode-bg-image: none;
            --panel-bg-image: var(--light-mode-bg-image);
            --panel-corner-radius: 0.5rem;

            --panel-background-color: var(--background--color);
            --panel-color: var(--color);
            --panel-bold-color: var(--bold-color);
            --panel-anchor-color: var(--anchor-color);
            --panel-anchor-hover-color: var(--anchor-hover-color);
            --panel-heading-color: var(--heading-color);
            --panel-accent-color: var(--accent-color);
            --color: var(--panel-color);
            
            .feature-panel {

                border-radius: var(--panel-corner-radius);
                background-color: var(--panel-background-color);
                color: var(--panel-color);

                strong, b {

                    color: var(--panel-bold-color);
                }

                h1, h2, h3, h4, h5, h6 {

                    color: var(--panel-heading-color);
                }

                a:not([role="button"]) {
                    color: var(--panel-anchor-color);
                }

                a:not([role="button"]):hover {
                    color: var(--panel-anchor-hover-color);
                }
                
                .panel-cell {
                    
                    padding: 1.5rem 1.5rem;

                    @include sf-media($from: tabp) {
                        padding: 3rem 3.5rem;
                        height: 100%;
                    }                    
                }
                
                .image-panel {

                    background-image: var(--panel-bg-image);
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    min-height: 15rem;
                    overflow: hidden;

                    border-top-left-radius: var(--panel-corner-radius);
                    border-top-right-radius: var(--panel-corner-radius);
                    
                    @include sf-media($from: tabp) {

                        border-top-left-radius: 0;
                        border-top-right-radius: 0;
                        
                        &.left {

                            border-top-left-radius: var(--panel-corner-radius);
                            border-bottom-left-radius: var(--panel-corner-radius);
                        }

                        &.right {

                            border-top-right-radius: var(--panel-corner-radius);
                            border-bottom-right-radius: var(--panel-corner-radius);
                        }
                    }
                }
            }
        }
    }
}

[dark-theme] {

    .page-block-content {

        .content-block.feature-panel-block {
            
            --panel-bg-image: var(--dark-mode-bg-image);
        }
    }
}

@media (prefers-color-scheme: dark) {

    [auto-theme] {

        .page-block-content {

            .content-block.feature-panel-block {

                --panel-bg-image: var(--dark-mode-bg-image);
            }
        }
    }
}
