.page-block-content {

    .content-block {

        &.button-block {

            // Overrides

            // Custom

            button, a[role=button], [sf-button] {

                border-width: 1px;
                border-style: solid;
            }
        }
    }
}
