.page-block-content {

    .content-block {

        &.umbraco-forms-block {

            // Overrides

            // Custom
            
            .heading-bar {

                text-align: center;
                padding-bottom: 1.25rem;
                border-bottom: solid 1px var(--sf-color-secondary);
                margin-bottom: 1.5rem;

                h1, h2, h3, h4, h5, h6 {

                    margin-bottom: 0;
                    
                    & + p {
                        
                        margin-top: 1rem;
                    }
                }

                @include sf-media($from: tabp) {

                    padding-bottom: 2rem;
                    margin-bottom: 3rem;
                }
            }
            
            .umbraco-forms-form {

                margin: auto;
            }
            
            .umbraco-forms-submit-message {
                
                display: block;
                text-align: center;
                font-size: 1.25rem;
            }
        }
    }
}
