:root {

    --video-overlay-color: rgba(0, 0, 0, 0.65);
    --video-overlay-caption-color: #ffffff;

    .tol-video-overlay {

        display: none;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9998;
        background-color: var(--video-overlay-color);
        cursor: pointer;

        &.show {
            display: block;
        }

        [sf-video-wrapper] {

            position: relative;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            border-radius: 0;
            z-index: 9999;

            @include sf-media($from: tabp) {
                border-radius: 0.25rem;
                max-height: 100vh;
                max-width: 150vh;
                padding: 3rem;
            }

            small {
                text-align: left;
                color: var(--video-overlay-caption-color);
            }
        }
    }
}
