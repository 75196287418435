/**
 * Font and other base styles are established in this file.
 * It should be called after the configuration files have been loaded.
 */
* {
    
    &,
    &:before,
    &:after { box-sizing: border-box; }

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    -webkit-text-size-adjust: none;
    -webkit-font-size-adjust: none;
    -webkit-marquee-increment: 0vw;
    
    word-spacing: initial;
    letter-spacing: initial;
    word-break: break-word;

    & > * {

        font-weight: inherit;
    }
}
    
html {

    --sf-font-size: var(--sf-zero-font-size);

    @include sf-media($from: phab) {

        --sf-font-size: var(--sf-phab-font-size);
    }

    @include sf-media($from: tabp) {

        --sf-font-size: var(--sf-tabp-font-size);
    }

    @include sf-media($from: tabl) {

        --sf-font-size: var(--sf-tabl-font-size);
    }

    @include sf-media($from: note) {

        --sf-font-size: var(--sf-note-font-size);
    }

    @include sf-media($from: desk) {

        --sf-font-size: var(--sf-desk-font-size);
    }

    @include sf-media($from: elas) {

        --sf-font-size: var(--sf-elas-font-size);
    }

    font-size: var(--sf-font-size);

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    p {
        
        margin-top: 0;
        margin-bottom: 1.25rem;
        
        &:last-child {
            
            margin-bottom: 0;
        }
        
        &[sf-tight] {
            
            margin-bottom: 0.5rem;
        }
    }
    
    pre, code {
        
        font-family: var(--sf-font-family-mono);
    }

    code {
        
        vertical-align: top;
    }
}

html, body {

    // Needed for mobile
    overflow-x: hidden;
    max-width: 100vw;
}

body {

    // Needed for mobile
    position: relative;

    [sf-elastic-wrapper] {

        max-width: var(--elas-breakpoint);
        margin-left: auto;
        margin-right: auto;
    }
}
